import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const MailTo = styled.a`
  color: inherit;
  text-decoration: underline;
`;

const UnexpectedError = () => {
  const { t } = useTranslation('onboarding');

  return (
    <>
      <span>{t('general.unexpectedError')} </span>
      <MailTo href="mailto:support@deepstreamtech.com?subject=Unexpected%20error">
        support@deepstreamtech.com
      </MailTo>
    </>
  );
};

export default UnexpectedError;
