import { merge } from 'lodash';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Heading, Paragraph } from './text';
import ChooseCompanyTypeField from './ChooseCompanyTypeField';
import AutoSave from './AutoSave';
import WizardNav from './WizardNav';
import Title from './Title';
import { useApi } from './ApiProvider';

const initialValues = {
  companyType: '',
};

const ChooseCompanyType = ({ stepIndex, numSteps, wizardState, updateStep, prevStep, nextStep }) => {
  const api = useApi();
  const { t } = useTranslation('onboarding');

  return (
    <div>
      <Title text={t('createCompany.createCompany')} />
      <Heading>
        {t('createCompany.useDeepStream')}
      </Heading>
      <Paragraph>
        {t('createCompany.ourSystemWorksDifferently')}
      </Paragraph>
      <Formik
        isInitialValid={wizardState && wizardState.companyType}
        initialValues={merge({}, initialValues, wizardState)}
        validationSchema={
          yup.object().shape({
            // gets mapped to `company.group`
            companyType: yup.string().required(t('createCompany.chooseCompanyType')),
          })
        }
        onSubmit={async (values) => {
          updateStep(values);
          const companyId = await api.getNextCompanyId();
          updateStep({ _id: companyId });
          nextStep();
        }}
        render={({ values, isSubmitting }) => (
          <Form>
            <ChooseCompanyTypeField />
            <WizardNav
              stepIndex={stepIndex}
              numSteps={numSteps}
              disabled={isSubmitting}
              goBack={() => {
                updateStep(initialValues);
                prevStep();
              }}
            />
            <AutoSave
              debounceMs={2000}
              values={values}
              onSave={updateStep}
            />
          </Form>
        )}
      />
    </div>
  );
};

export default ChooseCompanyType;
