import { useTranslation, Trans } from 'react-i18next';

import { useCurrentCompanyId } from '@deepstream/ui/currentCompanyId';
import { useMutation } from '@deepstream/ui/useMutation';
import {
  navigateToDashboard,
  Paragraph,
  Strong,
  DoneButton,
  BackButton,
  ButtonRow,
} from './updateProductTags';
import { useApi } from './ApiProvider';

export const UpdateProductTagsDontRemindMeStep = ({ send }) => {
  const api = useApi();
  const companyId = useCurrentCompanyId({ required: true });
  const { t } = useTranslation('onboarding');

  const [updateUserProfile] = useMutation(api.updateUserProfile);

  const handleDoneClick = async () => {
    try {
      await updateUserProfile({
        preferences: {
          hideProductTagMigrationReminder: true,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      navigateToDashboard(companyId);
    }
  };

  return (
    <div>
      <Paragraph>
        {t('updateProductTags.remindAgain')}
      </Paragraph>
      <Paragraph>
        <Trans t={t} i18nKey="updateProductTags.updateTagsFromCompanyProfile">
          {' '}
          <Strong />
          {' '}
        </Trans>
      </Paragraph>
      <Paragraph>
        {t('updateProductTags.otherUsers')}
      </Paragraph>
      <ButtonRow>
        <BackButton onClick={() => send('PREV_STEP')} />
        <DoneButton onClick={handleDoneClick} />
      </ButtonRow>
    </div>
  );
};
