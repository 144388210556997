import { useDebounce, useDeepEqualEffect } from './hooks';

// Component that calls the `onSave` callback at most every `debounceMs`
const AutoSave = ({ debounceMs, values, onSave }) => {
  const debouncedValues = useDebounce(values, debounceMs);

  useDeepEqualEffect(() => onSave(debouncedValues), debouncedValues);

  return null;
};

export default AutoSave;
