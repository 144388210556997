import Cookies from 'js-cookie';

export const setLocaleCookie = (locale: string) => {
  Cookies.set(
    `locale-${process.env.NX_ENV}`,
    locale,
    { domain: process.env.NX_COOKIE_DOMAIN },
  );
};

export const getLocaleCookie = () => Cookies.get(`locale-${process.env.NX_ENV}`);
