const PREVIOUS_PAGE = '/find-company';
const WIZARD_PAGE = '/create-company';
const NEXT_PAGE = '/verification';

// Helper function which gets adjacent routes
const getRoutes = (steps, stepIndex) => {
  const currentStep = steps[stepIndex];

  if (!currentStep) {
    throw new Error('Must provide a valid step index.');
  }

  const previousStep = steps[stepIndex - 1];
  const nextStep = steps[stepIndex + 1];

  return {
    previous: previousStep ? `${WIZARD_PAGE}/${previousStep.id}` : PREVIOUS_PAGE,
    current: `${WIZARD_PAGE}/${currentStep.id}`,
    next: nextStep ? `${WIZARD_PAGE}/${nextStep.id}` : NEXT_PAGE,
  };
};

export default getRoutes;
