import { useMemo } from 'react';
import { Form as FormikForm } from 'formik';
import { ErrorDisplay, ErrorDisplayContext } from './ErrorDisplayContext';

const Form = ({ children, errorDisplay = ErrorDisplay.ON_TOUCH, ...props }) => {
  const contextValue = useMemo(
    () => ({ errorDisplay }),
    [errorDisplay],
  );

  return (
    <ErrorDisplayContext.Provider value={contextValue}>
      <FormikForm {...props}>
        {children}
      </FormikForm>
    </ErrorDisplayContext.Provider>
  );
};

export default Form;
