import { createContext, useContext, useMemo, useEffect } from 'react';
import { ApiClient } from './apiClient';
import { useAuth } from './auth/getAuthHook';
import { LocalApiClient } from './local-auth/LocalApiClient';
import { isLocalAuth } from './auth/isLocalAuth';

export const ApiContext = createContext(null);

export const useApi = () => useContext(ApiContext);

export const ApiProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth();

  const api = useMemo(
    () => {
      if (isLocalAuth()) {
        return new LocalApiClient();
      }
      return new ApiClient();
    },
    [],
  );

  useEffect(
    () => {
      api.setTokenGenerator(getAccessTokenSilently);
    },
    [api, getAccessTokenSilently],
  );

  return (
    <ApiContext.Provider value={api}>
      {children}
    </ApiContext.Provider>
  );
};
