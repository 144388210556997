import { useState, useCallback, useMemo } from 'react';
import * as React from 'react';
import { noop, isFunction } from 'lodash';
import styled from 'styled-components';
import { Text, Box, BoxProps, Flex } from 'rebass/styled-components';
import { SpaceProps, DisplayProps } from 'styled-system';
import { truncateStyle } from '@deepstream/ui-kit/elements/text/Truncate2';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { ButtonProps } from '@deepstream/ui-kit/elements/button/Button';
import { Dialog } from '@deepstream/ui-kit/elements/popup/Dialog';
import { Stack } from '@deepstream/ui-kit/elements/Stack';
import { useModalState } from './useModalState';

// Autofocus will only be used after the user has already expressed their intent to reply
/* eslint-disable jsx-a11y/no-autofocus */

const hiddenRadioStyle: React.CSSProperties = {
  position: 'absolute',
  opacity: 0,
  zIndex: -1,
};

export const StyledRadio = styled.div<{ checked?: boolean; hover?: boolean; focused?: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props =>
    props.disabled
      ? props.theme.colors.gray
      : props.checked || props.hover
        ? props.theme.colors.primary
        : props.theme.colors.lightGray};
  border-radius: 999px;
  background: ${props => props.theme.colors.white};
  transition: all 150ms;
  font-size: 12px;
  opacity: ${props => props.disabled ? 0.5 : 1};
  transition:
    color 300ms,
    background-color 300ms,
    border-color 300ms,
    box-shadow 300ms;

  ${props => props.focused && !props.disabled ? 'box-shadow: 0 0 0 4px rgba(52, 152, 219, 0.35)' : ''};

  :after {
    content: '';
    position: absolute;
    background-color: ${props => props.disabled ? props.theme.colors.gray : props.theme.colors.primary};
    width: 10px;
    height: 10px;
    border-radius: 999px;
    transform: ${props => props.checked ? 'scale(1)' : 'scale(0)'};
    transition: all 300ms;
  }

  &:hover {
    border-color: ${props => props.disabled ? '' : props.theme.colors.primary};
  }
`;

interface Input {
  label: string;
  value: string;
  description?: string;
  descriptionTestAttribute?: string;
  separatorAbove?: React.ReactNode;
}

export interface RadioGroupProps {
  name: string;
  inputs: Input[];
  defaultValue?: RadioProps['value'];
  onChange: any;
}

export const RadioGroup: React.FC<RadioGroupProps & BoxProps> = ({ name, inputs, onChange, defaultValue, ...props }) => {
  const [value, setValue] = React.useState<RadioProps['value']>(defaultValue);

  useWatchValue(value, onChange);

  return (
    <Stack gap={2} {...props}>
      {inputs.map(input => (
        <Box key={input.value}>
          <Radio
            display="inline-block"
            name={name}
            label={input.label}
            value={input.value}
            description={input.description}
            checked={input.value === value}
            onChange={event => {
              setValue((event.target as HTMLInputElement).value);
            }}
          />
        </Box>
      ))}
    </Stack>
  );
};

const StackContainer = (props) => (
  <Stack gap={2} {...props} />
);

const InlineContainer = (props) => (
  <Flex {...props} />
);

export type ControlledRadioGroupProps = {
  name?: string;
  value?: RadioProps['value'];
  variant?: 'inline' | 'stacked';
  onContainerBlur?: (event: React.FocusEvent<HTMLDivElement, any>) => void;
  labelStyle?: any;
  disabled?: boolean;
  hasBoxStyle?: boolean;
  onChange?: any;
  inputs: Input[]
  autoFocus?: boolean;
};

export const ControlledRadioGroup = ({
  name,
  inputs,
  onChange,
  onContainerBlur,
  value,
  variant = 'stacked',
  disabled,
  labelStyle,
  hasBoxStyle,
  ...props
}: ControlledRadioGroupProps) => {
  const isInline = variant === 'inline';
  const Container = isInline ? InlineContainer : StackContainer;

  return (
    <Container {...props} onBlur={onContainerBlur}>
      {inputs.map((input: any) => (
        <React.Fragment key={input.value}>
          {input.separatorAbove}
          <Tooltip content={input.tooltip}>
            <Box key={input.value} display={isInline ? 'inline' : 'block'} mr={isInline ? '24px' : 0}>
              <Radio
                display="inline-block"
                name={name}
                label={input.label}
                labelStyle={labelStyle}
                // @ts-expect-error ts(2322) FIXME: Type '{ border: string; borderRadius: string; width: string; padding: string; } | null' is not assignable to type 'CSSProperties | undefined'.
                boxStyle= {hasBoxStyle
                  ? {
                    border: `${input.value === value ? '#2B23FA' : '#ADB2BD'} 1px solid`,
                    borderRadius: '5px',
                    width: '540px',
                    padding: '15px',
                  }
                  : null}
                value={input.value}
                description={input.description}
                descriptionTestAttribute={input.descriptionTestAttribute}
                checked={input.value === value}
                disabled={disabled || input.disabled}
                onChange={event => {
                  onChange((event.target as HTMLInputElement).value);
                }}
                autoFocus={input.autoFocus}
              />
            </Box>
          </Tooltip>
        </React.Fragment>
      ))}
    </Container>
  );
};

export type GuardDialogProps = {
  heading: string;
  body: (value: any) => React.ReactNode | React.ReactNode;
  okButtonVariant?: ButtonProps['variant'];
  okButtonText?: string;
  cancelButtonVariant?: ButtonProps['variant'];
  cancelButtonText?: string;
  invertGuards: boolean;
};

type GuardedRadioGroupProps =
  & { guardedValues?: string[] }
  & Omit<ControlledRadioGroupProps, 'value'>
  & Omit<BoxProps, 'variant'>
  & GuardDialogProps;

export const GuardedRadioGroup = ({
  guardedValues,
  onChange = noop,
  heading,
  body,
  okButtonText,
  okButtonVariant,
  cancelButtonText,
  cancelButtonVariant,
  invertGuards,
  defaultValue,
  ...props
}: GuardedRadioGroupProps) => {
  const confirmationDialog = useModalState();

  // Hold desired value until
  const [value, setValue] = useState<string | undefined>(defaultValue as string);
  const [pendingValue, setPendingValue] = useState<string | undefined>(undefined);

  const pendingInput = useMemo(
    () => props.inputs.find(input => input.value === pendingValue),
    [props.inputs, pendingValue],
  );

  useWatchValue(value, onChange);

  const handleChange = useCallback(
    (value) => {
      if (
        guardedValues && guardedValues.length && (
          (!invertGuards && guardedValues.includes(value)) ||
          (invertGuards && !guardedValues.includes(value))
        )
      ) {
        setPendingValue(value);
        confirmationDialog.open();
      } else {
        setValue(value);
      }
    },
    [invertGuards, guardedValues, setPendingValue, confirmationDialog, setValue],
  );

  const onConfirm = useCallback(
    () => {
      setPendingValue(undefined);
      setValue(pendingValue);
      confirmationDialog.close();
    },
    [setValue, pendingValue, confirmationDialog],
  );

  const onCancel = useCallback(
    () => {
      setPendingValue(undefined);
      confirmationDialog.close();
    },
    [confirmationDialog, setPendingValue],
  );

  return (
    <>
      <ControlledRadioGroup
        value={value}
        onChange={handleChange}
        {...props}
      />
      <Dialog
        heading={heading}
        body={isFunction(body) ? body(pendingInput) : body}
        okButtonText={okButtonText}
        okButtonVariant={okButtonVariant}
        cancelButtonText={cancelButtonText}
        cancelButtonVariant={cancelButtonVariant}
        isOpen={confirmationDialog.isOpen}
        onOk={onConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export type RadioProps =
  {
    label?: string | React.ReactNode;
    truncate?: boolean;
    description?: string | React.ReactNode;
    descriptionTestAttribute?: string;
    labelStyle?: React.CSSProperties;
    boxStyle?: React.CSSProperties;
    useDefaultDisabledStyle?: boolean;
  } &
  React.HTMLProps<HTMLInputElement> &
  SpaceProps &
  DisplayProps;

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(({
  display,
  checked,
  label,
  truncate,
  mb,
  value,
  description,
  descriptionTestAttribute,
  disabled,
  children,
  labelStyle = {},
  boxStyle = {},
  useDefaultDisabledStyle = true,
  ...props
}, ref) => {
  const [focused, setFocus] = React.useState(false);
  const [hoverRef, hover] = useHover<HTMLLabelElement>();

  return (
    <Box mb={mb} display={display} style={boxStyle}>
      <label
        ref={hoverRef}
        style={{ display: 'flex', alignItems: description ? 'flex-start' : 'center', ...labelStyle }}
      >
        <div>
          <input
            type="radio"
            checked={checked}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            style={hiddenRadioStyle}
            value={value}
            disabled={disabled}
            ref={ref}
            {...props}
          />
          <StyledRadio
            hover={hover}
            checked={checked}
            focused={focused}
            disabled={disabled}
          />
        </div>
        <Box pl={2} opacity={disabled && useDefaultDisabledStyle ? 0.5 : 1}>
          {label && (
            <Text fontFamily="primary" fontSize={2} style={truncate ? truncateStyle : undefined}>
              {label}
            </Text>
          )}
          {description && (
            <Text
              fontFamily="primary"
              color={disabled ? 'text' : 'subtext'}
              fontSize={1}
              fontWeight={400}
              style={truncate ? truncateStyle : undefined}
              mt={1}
              data-test={descriptionTestAttribute}
            >
              {description}
            </Text>
          )}
        </Box>
        {children}
      </label>
    </Box>
  );
});

Radio.displayName = 'Radio';
