import { Route, Redirect, withRouter } from 'react-router-dom';
import steps from './createCompanyWizardSteps';
import * as Layout from './Layout';
import { useWizard } from './hooks';

const CreateCompanyWizard = withRouter(({ match }) => {
  const {
    isLoading,
    shouldRedirect,
    wizardState,
    currentStep,
    currentStepIndex,
    redirectStepIndex,
    hasSubmitted,
    error,
    updateStep,
    prevStep,
    nextStep,
  } = useWizard(steps, match.params.stepId);

  if (!match.params.stepId) {
    // When hitting the wizard's root route, we redirect to the first step. This
    // allows any knowledge concerning the wizard's steps to remain local to the
    // wizard (ie: ideally, parent components shouldn't have an understanding of
    // the steps involved)
    return <Redirect to={currentStep.routes.current} />;
  } else if (shouldRedirect) {
    if (redirectStepIndex < currentStepIndex) {
      return <Redirect to={currentStep.routes.previous} push />;
    } else if (redirectStepIndex > currentStepIndex && (!currentStep.isLast || hasSubmitted)) {
      return <Redirect to={currentStep.routes.next} push />;
    }
  }

  if (isLoading) {
    return null;
  }

  return (
    <Layout.Page wide>
      {steps.map(({ id, Component, routes }, index) => (
        <Route
          key={id}
          exact
          path={routes.current}
          render={() => (
            <Component
              wizardState={wizardState}
              error={error}
              stepIndex={index}
              numSteps={steps.length}
              updateStep={updateStep}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          )}
        />
      ))}
    </Layout.Page>
  );
});

export default CreateCompanyWizard;
