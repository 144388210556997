import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserLocale } from '@deepstream/common/user-utils';
import { useTranslation } from 'react-i18next';

import { getRegionName } from '@deepstream/common';
import DescriptionButton from './DescriptionButton';
import * as Text from './text';
import { useSession } from './hooks';

const Title = styled(Text.Small)`
  display: block;
  font-weight: 500;
  color: ${props => props.theme.colors.lightNavy};
  margin-bottom: 10px;
`;

const List = styled.ul`
  padding-left: 0;
  list-style: none;
`;

const TITLE_ID = 'suggested-companies-title';

const SuggestedCompaniesList = ({ companies }) => {
  const { t } = useTranslation('onboarding');
  const session = useSession();
  const locale = getUserLocale(session.currentUser);

  return (
    <div>
      <Title id={TITLE_ID}>
        {t('findCompany.suggestedCompanies')}
      </Title>
      <List aria-labelledby={TITLE_ID}>
        {companies.map(company => (
          <li aria-label={company.name} key={company._id}>
            <DescriptionButton
              label={company.name}
              description={(
                <>
                  <FontAwesomeIcon icon="map-marker-alt" style={{ marginRight: 7 }} />
                  {getRegionName(get(company, 'address.country'), locale, t('unknown', { ns: 'general' }))}
                </>
              )}
              imageUrl={company.logoUrl}
              to={`/join-company/${company._id}`}
            />
          </li>
        ))}
      </List>
    </div>
  );
};

SuggestedCompaniesList.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SuggestedCompaniesList;
