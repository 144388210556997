import { useState, useEffect } from 'react';

// State that resets itself after the given `timeout`
const useResettingState = (initialValue, timeout) => {
  const [value, setValue] = useState(initialValue);

  useEffect(
    () => {
      if (!value) return;

      setTimeout(() => setValue(initialValue), timeout);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  return [value, setValue];
};

export default useResettingState;
