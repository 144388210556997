import useMedia from './useMedia';

const useDeviceSize = () => {
  const isExtraSmall = useMedia('(min-width: 0px) and (max-width: 575px)');
  const isSmall = useMedia('(min-width: 576px) and (max-width: 767px');
  const isMedium = useMedia('(min-width: 768px) and (max-width: 1099px)');
  const isLarge = useMedia('(min-width: 1100px) and (max-width: 1299px)');
  const isExtraLarge = useMedia('(min-width: 1200px)');

  return {
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
  };
};

export default useDeviceSize;
