import { useHistory } from 'react-router-dom';
import { Auth0Provider as Provider } from '@auth0/auth0-react';

export const Auth0Provider = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    const redirectUrl = appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname;

    history.push(redirectUrl);
  };

  return (
    <Provider
      domain={process.env.NX_AUTH0_DOMAIN}
      clientId={process.env.NX_AUTH0_CLIENT_ID}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        audience: process.env.NX_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
        scope: 'openid profile email *:*',
      }}
      useRefreshTokens
      useRefreshTokensFallback
      // Auth0 cookies are namespaced by `clientId` so we can set all environments
      // to use the same top level cookie domain.
      cookieDomain=".deepstreamtech.com"
    >
      {children}
    </Provider>
  );
};
