import { CountriesProvider } from '@deepstream/ui/ui/countries';
import { useApi } from './ApiProvider';

export const CountriesProviderWrapper = ({ children }) => {
  const api = useApi();

  return (
    <CountriesProvider getCountryCodes={() => api.getCountryCodes()}>
      {children}
    </CountriesProvider>
  );
};
