import styled from 'styled-components';
import { darken } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Link from './Link';
import * as Layout from './Layout';

const StyledLink = styled(Link)`
  transition: all 0.2s ease-in-out;
  color: ${props => props.theme.colors.primary};

  &:hover {
    color: ${props => darken(props.theme.darken.dark, props.theme.colors.primary)};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 11px;
  font-size: 33px;
`;

const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const CreateCompanyLink = () => {
  const { t } = useTranslation('onboarding');

  return (
    <StyledLink primary to="/create-company" data-test="create-link">
      <Layout.Row align="center">
        <Icon icon="plus-circle" />
        <Label>{t('createCompany.createYourCompany')}</Label>
      </Layout.Row>
    </StyledLink>
  );
};

export default CreateCompanyLink;
