import { merge, findIndex } from 'lodash';
import { createElement, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import useDeepEqualEffect from './useDeepEqualEffect';
import useRedirection from './useRedirection';
import UnexpectedError from '../UnexpectedError';
import { loadOnboardingProgress, saveOnboardingProgress } from '../apiClient';
import { useApi } from '../ApiProvider';

const useWizard = (steps, stepId) => {
  const api = useApi();

  const currentStepIndex = stepId ? findIndex(steps, { id: stepId }) : 0;
  const currentStep = steps[currentStepIndex];

  /* State */

  const [isLoading, setLoading] = useState(true);
  const [wizardState, setStepsState] = useState({});
  const [shouldRedirect, redirect] = useRedirection();
  const [hasSubmitted, setSubmitted] = useState(false);
  const [redirectStepIndex, setStepIndex] = useState(currentStepIndex);
  const [error, setError] = useState(null);

  /* Actions */

  const updateStep = stepState => {
    setStepsState(wizardState => merge({}, wizardState, stepState));
  };

  const prevStep = () => {
    setStepIndex(stepIndex => stepIndex - 1);
    redirect();
  };

  const nextStep = () => {
    setStepIndex(stepIndex => stepIndex + 1);
    redirect();
  };

  /* Effects */

  useEffect(
    () => {
      setLoading(true);

      loadOnboardingProgress()
        .then(state => {
          setStepsState(state);
          setLoading(false);
        });
    },
    [],
  );

  // If the wizard state changes, save the progress
  useDeepEqualEffect(
    () => {
      saveOnboardingProgress(wizardState);
    },
    wizardState,
  );

  // Submit company for verification if on last step of the wizard
  useEffect(
    () => {
      if (currentStep.isLast && redirectStepIndex > currentStepIndex) {
        api
          .submitCompanyForVerification()
          .then(() => {
            ReactDOM.unstable_batchedUpdates(() => {
              setSubmitted(true);
              redirect(true);
            });
          })
          .catch(() => setError(createElement(UnexpectedError)));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [redirectStepIndex], // is this right?
  );

  return {
    isLoading,
    shouldRedirect,
    currentStep,
    currentStepIndex,
    redirectStepIndex,
    hasSubmitted,
    wizardState,
    updateStep,
    prevStep,
    nextStep,
    error,
  };
};

export default useWizard;
