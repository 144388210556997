export enum UserStatus {
  ONBOARDING = 'onboarding',
  COMPLETED = 'completed',
}

export enum SignUpResponseStates {
  pendingVerification = 'pendingVerification',
  verifiedDefaultCompany = 'verifiedDefaultCompany',
  verifiedNewCompany = 'verifiedNewCompany',
  unverified = 'unverified',
}
