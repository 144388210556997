import { size } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';

import { useCurrentCompanyId } from '@deepstream/ui/currentCompanyId';
import {
  navigateToDashboard,
  Paragraph,
  Strong,
  DoneButton,
  BackButton,
  ButtonRow,
} from './updateProductTags';

export const UpdateProductTagsDoneStep = ({ send, company }) => {
  const { t } = useTranslation('onboarding');

  const companyId = useCurrentCompanyId({ required: true });
  const numTags = size(company.productsAndServices);

  const handleDoneClick = () => navigateToDashboard(companyId);

  return (
    <div>
      <Paragraph>
        {t('updateProductTags.selectedNewProductTags', { count: numTags })}
      </Paragraph>
      <Paragraph>
        {t('updateProductTags.visibleTags', { count: numTags })}
      </Paragraph>
      <Paragraph>
        <Trans t={t} i18nKey="updateProductTags.promptYou" values={{ count: numTags }}>
          {' '}
          <Strong />
          {' '}
        </Trans>
      </Paragraph>
      <ButtonRow>
        <BackButton onClick={() => send('PREV_STEP')} />
        <DoneButton onClick={handleDoneClick} />
      </ButtonRow>
    </div>
  );
};
