import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@deepstream/ui-kit/elements/popup/Tooltip';
import { Row } from './Layout';

const ItemsRow = styled(Row)`
   font-size: ${props => props.theme.fonts.small};
   color: ${props => props.theme.colors.gray};
 `;

const MoreLink = styled.span`
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  margin-left: 8px;
`;

const TruncatedList = ({ list = [], numDisplayedItems = 1, separator = ',', ...props }) => {
  const { t } = useTranslation();
  const items = [];

  const stringList = props.key
    ? list.map(item => item[props.key])
    : list;

  const visibleItems = stringList.slice(0, numDisplayedItems);
  const tooltipItems = stringList.slice(numDisplayedItems, stringList.length).join('\n');

  for (const [index, value] of visibleItems.entries()) {
    items.push(
      <span>{value}{index < stringList.length ? separator : ''}</span>,
    );
  }

  return (
    <ItemsRow justify="flex-end">
      {visibleItems}
      {tooltipItems.length > 0 && (
        <Tooltip content={tooltipItems}>
          <MoreLink>+{t('teamManagement.moreCount', { count: list.length - numDisplayedItems })} </MoreLink>
        </Tooltip>
      )}
    </ItemsRow>
  );
};

export default TruncatedList;
