import { createContext, useContext } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { useApi } from './ApiProvider';
import ErrorMessage from './ErrorMessage';

const SystemFeatureFlagsContext = createContext();

export const SystemFeatureFlagsProvider = ({ children }) => {
  const api = useApi();
  const { t } = useTranslation('onboarding');

  const { data: systemFeatureFlags, isSuccess, isError } = useQuery(
    ['systemFeatureFlags'],
    () => api.getSystemFeatureFlags(),
  );

  return isSuccess && systemFeatureFlags ? (
    <SystemFeatureFlagsContext.Provider value={systemFeatureFlags}>
      {children}
    </SystemFeatureFlagsContext.Provider>
  ) : isError ? (
    <ErrorMessage panel error={t('general.couldNotGetFeatureFlags')} />
  ) : (
    null
  );
};

export const useSystemFeatureFlags = () => useContext(SystemFeatureFlagsContext);
