import { useEffect } from 'react';
import { useSession } from '../hooks';

export function Logout() {
  const { logout } = useSession();

  useEffect(() => {
    (async () => {
      await logout();
    })();
  }, [logout]);
  return null;
}
