export default {
  border: {
    width: '1px',
    radii: {
      sharp: '2px',
      regular: '8px',
      round: '100px',
    },
  },
  borders: {
    gray: '1px solid #7E8890',
  },
  colors: {
    // Primary color
    primary: '#3200FF',
    secondary: '#F0F0F5',

    // Text colors
    text: '#111010',
    subtext: '#7E8890',
    disabled: '#7E8890',

    // Status colors
    success: '#18BC9C',
    warning: '#F39C12',
    danger: '#FF5C40',
    info: '#3200FF',

    // Grays
    lightGray: '#ADB2BD',
    lightGray2: '#D9D9D9',
    gray: '#111010',
    darkGray: '#111010',
    darkGray2: '#333C4E',

    // Misc
    navy: '#111010',
    lightNavy: '#111010',
    white: '#ffffff',
    background: '#ffffff',
  },
  fonts: {
    primary: 'Inter, aktiv-grotesk, sans-serif',
    secondary: 'din-2014, sans-serif',
    small: '12px',
  },
  darken: {
    dark: 0.10,
    darker: 0.20,
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256,
  ],
  fontSizes: [
    11, 12, 14, 16, 18, 20, 24, 32, 48, 64,
  ],
};
