import DeepStreamDarkLogo from './DeepStreamDarkLogo';

const Logo = () => (
  <div>
    <a href={process.env.NX_LANDING_URL} style={{ display: 'block', lineHeight: 0 }}>
      <DeepStreamDarkLogo />
    </a>
  </div>
);

export default Logo;
