import { useTranslation } from 'react-i18next';

import { Heading, Paragraph } from './text';
import Message from './Message';
import ButtonLink from './ButtonLink';
import * as Layout from './Layout';
import { useAuthContext } from './hooks';
import ResendEmailVerification from './ResendEmailVerification';

const SignUpSuccess = () => {
  const { currentUser, hasSomeVerifiedMemberships } = useAuthContext();
  const { t } = useTranslation('onboarding');

  if (currentUser.verified) {
    return (
      <Layout.Page title={t('general.signUp')}>
        <Heading>
          {t('general.success')}
        </Heading>
        <Message type="success">
          {hasSomeVerifiedMemberships ? (
            t('signUp.accountCreatedStartUsing')
          ) : (
            t('signUp.yourAccountCreated')
          )}
        </Message>
        <Layout.Row justify="center">
          <ButtonLink href={process.env.NX_APP_URL} data-test="dashboard">
            {hasSomeVerifiedMemberships ? t('general.goToDashboard') : t('continue', { ns: 'general' })}
          </ButtonLink>
        </Layout.Row>
      </Layout.Page>
    );
  }

  return (
    <Layout.Page title={t('general.signUp')}>
      <Heading>
        {t('signUp.pleaseCheckEmail')}
      </Heading>
      <Message type="success">
        {t('signUp.accountCreated')}
      </Message>
      <Paragraph>
        {t('signUp.useEmailToLogin', { email: currentUser.email })}
      </Paragraph>
      <ResendEmailVerification />
    </Layout.Page>
  );
};

export default SignUpSuccess;
