import * as React from 'react';
import { Card, Box, Text, Heading } from 'rebass/styled-components';
import { SpaceProps } from 'styled-system';
import { TagLevel1 } from './types';

const capitalize = (string: string) => string.replace(/./, x => x.toUpperCase());

export const Pill = ({ children, ...props }: SpaceProps & { children: React.ReactNode }) => (
  <div style={{ display: 'inline-block' }}>
    <Card color="white" bg="primary" px={2} py={1} sx={{ borderRadius: 30 }} {...props}>
      <Text fontSize={0}>
        {children}
      </Text>
    </Card>
  </div>
);

export const StructuredTags = ({ tags = [], ...props }: { tags: TagLevel1[] } & SpaceProps) => (
  <Box {...props}>
    {tags.map(tag => (
      <Box key={tag._id}>
        <Heading fontSize={2} fontWeight={500} mb={3}>{capitalize(tag.name)}</Heading>
        {tag.children.map(tag => (
          <Box key={tag._id} mb={2}>
            <Text color="subtext" fontSize={1} mb={1}>{capitalize(tag.name)}</Text>
            <Box>
              {tag.children.map(tag => (
                <Pill key={tag._id} mr={2} mb={2}>{capitalize(tag.name)}</Pill>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    ))}
  </Box>
);
