import { useContext } from 'react';
import { Field } from 'formik';
import { camelCase, get } from 'lodash';
import UploadLogo from './UploadLogo';
import { ErrorDisplay, ErrorDisplayContext } from './ErrorDisplayContext';

const UploadLogoField = ({ label, name, companyId, isRequired }) => {
  const { errorDisplay } = useContext(ErrorDisplayContext);

  return (
    <Field
      id={camelCase(name)}
      name={name}
      render={({ field, form }) => (
        <UploadLogo
          {...field}
          id={name}
          name={name}
          label={label}
          isRequired={isRequired}
          companyId={companyId}
          error={
              // Using `_.get` because the `name` might represent a nested object's path
              errorDisplay === ErrorDisplay.ALWAYS
                ? get(form.errors, name)
                : get(form.touched, name) && get(form.errors, name)
            }
        />
      )}
    />
  );
};

export default UploadLogoField;
