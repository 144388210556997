import { useTranslation, Trans } from 'react-i18next';

import { useCurrentCompanyId } from '@deepstream/ui/currentCompanyId';
import {
  navigateToDashboard,
  Paragraph,
  Strong,
  DoneButton,
  BackButton,
  ButtonRow,
} from './updateProductTags';

export const UpdateProductTagsRemindMeStep = ({ send }) => {
  const companyId = useCurrentCompanyId({ required: true });
  const { t } = useTranslation('onboarding');

  const handleDoneClick = () => navigateToDashboard(companyId);

  return (
    <div>
      <Paragraph>
        {t('updateProductTags.nextTimeYouSignIn')}
      </Paragraph>
      <Paragraph>
        <Trans t={t} i18nKey="updateProductTags.updateTagsFromCompanyProfile">
          {' '}
          <Strong />
          {' '}
        </Trans>
      </Paragraph>
      <Paragraph>
        {t('updateProductTags.otherUsers')}
      </Paragraph>
      <ButtonRow>
        <BackButton onClick={() => send('PREV_STEP')} />
        <DoneButton onClick={handleDoneClick} />
      </ButtonRow>
    </div>
  );
};
