import styled from 'styled-components';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import DescriptionButton from './DescriptionButton';
import ErrorMessage from './ErrorMessage';

const useCompanyTypes = () => {
  const { t } = useTranslation('onboarding');

  return [
    {
      type: 'buyer',
      label: t('buyer', { ns: 'general' }),
      description: t('createCompany.superchargeProcurement'),
      imageUrl: `${process.env.NX_API_URL}/images/buyer.svg`,
    },
    {
      type: 'supplier',
      label: t('supplier', { count: 1, ns: 'general' }),
      description: t('createCompany.supplyGoods'),
      imageUrl: `${process.env.NX_API_URL}/images/supplier.svg`,
    },
    {
      type: 'both',
      label: t('general.both'),
      description: t('createCompany.canDoBoth'),
      imageUrl: `${process.env.NX_API_URL}/images/both.svg`,
    },
  ];
};

const ChooseCompanyTypeContainer = styled.div`
  margin-Top: 31px;
  margin-bottom: 34px;
`;

const ChooseCompanyType = ({ field, form }) => {
  const companyTypes = useCompanyTypes();

  return (
    <ChooseCompanyTypeContainer>
      <ErrorMessage
        panel
        error={form.errors[field.name]}
        style={{ marginBottom: 30 }}
      />
      {companyTypes.map(({ type, label, description, imageUrl }) => (
        <DescriptionButton
          key={type}
          label={label}
          description={description}
          imageUrl={imageUrl}
          selected={field.value === type}
          onClick={() => {
            form.setTouched({ [field.name]: true });
            form.setFieldValue(field.name, type);
          }}
        />
      ))}
    </ChooseCompanyTypeContainer>
  );
};

const ChooseCompanyTypeField = () => (
  <Field name="companyType" component={ChooseCompanyType} />
);

export default ChooseCompanyTypeField;
