import { useMemo } from 'react';
import { map } from 'lodash';
import { ProductTag } from '@deepstream/common/products';
import { PanelProps } from '@deepstream/ui-kit/elements/Panel';
import { FilteredProductsPanel } from './FilteredProductsList';
import { useProducts } from './useProducts';

export const ViewProductsPanel = ({
  productsAndServices,
  panelProps,
}: {
  /**
   * The product and service tags to display in the panel.
   */
  productsAndServices: ProductTag[];
  panelProps?: Partial<PanelProps>;
}) => {
  const { initialProducts, selectedProductIds } = useMemo(
    () => ({
      initialProducts: productsAndServices,
      selectedProductIds: map(productsAndServices, '_id'),
    }),
    [productsAndServices],
  );

  const {
    state,
    actions,
    showSearchResults,
    products,
    isProductsLoading,
    isProductsError,
    isSearching,
    isSearchError,
    refetchProducts,
    refetchSearch,
  } = useProducts({
    initialProducts,
    selectedProductIds,
    searchWithinSelectedProducts: true,
  });

  return (
    <FilteredProductsPanel
      productsState={state}
      productsActions={actions}
      showSearchResults={showSearchResults}
      panelHeight="100%"
      isReadOnly
      products={products}
      isProductsLoading={isProductsLoading}
      isProductsError={isProductsError}
      isSearching={isSearching}
      isSearchError={isSearchError}
      refetchProducts={refetchProducts}
      refetchSearch={refetchSearch}
      panelProps={panelProps}
    />
  );
};
