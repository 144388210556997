import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from './Layout';
import Message from './Message';

const ErrorMessageContainer = styled(Row)`
  margin-top: 5px;
  font-size: 12px;
  color: ${props => props.theme.colors.danger};
`;

const ErrorText = styled.span`
  color: ${props => props.theme.colors.danger};
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  height: 12px;
`;

/* eslint-disable no-nested-ternary */

const ErrorMessage = ({ error, panel, style }) => {
  if (!error) return null;

  if (panel) {
    return (
      <Message type="danger" style={style}>
        <ErrorText>{error}</ErrorText>
      </Message>
    );
  } else {
    return (
      <ErrorMessageContainer align="center" style={style}>
        <Icon icon="exclamation-circle" />
        <ErrorText>{error}</ErrorText>
      </ErrorMessageContainer>
    );
  }
};

export default ErrorMessage;
