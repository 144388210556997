import { memo, useMemo, useCallback } from 'react';
import { Field } from 'formik';
import { uniqBy } from 'lodash';
import { ApiProvider } from '@deepstream/ui/api';
import { ClientThemeProvider } from '@deepstream/ui/ClientThemeProvider';
import { ToastProvider } from '@deepstream/ui/toast';
import { EnvProvider } from '@deepstream/ui/env';
import { useTranslation } from 'react-i18next';
import { Panel, PanelDivider, PanelHeader, PanelText } from '@deepstream/ui-kit/elements/Panel';
import { HelpCenterLink } from '@deepstream/ui/HelpCenterLink';
import { EditProductsPanel } from '@deepstream/ui/EditProductsPanel';

const ProductPicker = memo(({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <Panel style={{ width: 'min(808px, calc(85vw - 16px))' }}>
      <PanelHeader heading={t('productsAndServices.productsAndServices')} />
      <PanelDivider />
      <EditProductsPanel value={value} onChange={onChange} />
      <PanelDivider />
      <PanelText px={3} height="62px">
        <HelpCenterLink
          text={t('general.moreInformation')}
          path="/en/articles/6337679-search-for-new-suppliers-using-product-services-tags"
        />
      </PanelText>
    </Panel>
  );
});

const WrappedProductPicker = ({ field, form }) => {
  const onChange = useCallback(
    (value) => {
      form.setTouched({ [field.name]: true });
      form.setFieldValue(field.name, value);
    },
    [field.name, form],
  );

  const uniqueValues = useMemo(() => uniqBy(field.value, '_id'), [field.value]);

  const env = {
    HELP_CENTER_URL: process.env.NX_HELP_CENTER_URL,
  };

  return (
    <EnvProvider env={env}>
      <ApiProvider baseUrl={process.env.NX_API_URL}>
        <ClientThemeProvider>
          <ToastProvider>
            <ProductPicker
              value={uniqueValues}
              onChange={onChange}
            />
          </ToastProvider>
        </ClientThemeProvider>
      </ApiProvider>
    </EnvProvider>
  );
};

const ProductPickerField = () => (
  <Field
    id="productsAndServices"
    name="productsAndServices"
    component={WrappedProductPicker}
  />
);

export default ProductPickerField;
