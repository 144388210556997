import { useTranslation } from 'react-i18next';

import ButtonLink from './ButtonLink';
import { Heading, Paragraph } from './text';
import * as Layout from './Layout';

const ChangePasswordSuccess = () => {
  const { t } = useTranslation('onboarding');

  return (
    <Layout.Page title={t('passwordReset.changePassword')}>
      <Heading>
        {t('passwordReset.passwordChanged')}
      </Heading>
      <Paragraph>
        {t('passwordReset.yourPasswordChanged')}
      </Paragraph>
      <ButtonLink to="/">
        {t('passwordReset.logInNow')}
      </ButtonLink>
    </Layout.Page>
  );
};

export default ChangePasswordSuccess;
