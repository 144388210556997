import { useTranslation } from 'react-i18next';

import { Heading } from './text';
import * as Layout from './Layout';
import ErrorMessage from './ErrorMessage';
import ResendEmailVerification from './ResendEmailVerification';
import { ContactSupportLink } from './ContactSupportLink';
import Button from './Button';

const EmailVerificationError = ({ location, history }) => {
  const { t } = useTranslation('onboarding');

  const searchParams = new URLSearchParams(location.search);
  const message = searchParams.get('message');
  const canResend = searchParams.get('canResend');
  const contactSupport = searchParams.get('contactSupport');
  const continueToLogin = searchParams.get('continueToLogin');

  return (
    <Layout.Page title={t('emailVerification.verificationError')}>
      <Heading>
        {t('emailVerification.errorVerifyingEmail')}
      </Heading>
      <ErrorMessage
        panel
        error={(
          <>
            {message || t('emailVerification.errorOccurred')}
            {contactSupport === 'true' && (
              <>
                {' '}
                <ContactSupportLink>
                  {t('emailVerification.contactSupport')}
                </ContactSupportLink>
                .
              </>
            )}
          </>
        )}
      />
      {canResend === 'true' && <ResendEmailVerification />}
      {continueToLogin === 'true' && (
        <Layout.Row style={{ marginTop: '20px' }}>
          <Button onClick={() => history.push('/')}>
            {t('continue', { ns: 'general' })}
          </Button>
        </Layout.Row>
      )}
    </Layout.Page>
  );
};

export default EmailVerificationError;
