import styled from 'styled-components';

/* A button that looks like a link */

const LinkButton = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  color: ${props => props.theme.colors.primary};
  font-size: inherit;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primaryDark};
  }
`;

export default LinkButton;
