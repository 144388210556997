import { useMemo } from 'react';
import { assign, values } from 'lodash';
import { useMachine } from '@xstate/react';

const mergeMeta = (meta) => assign(...values(meta));

const getInitialState = (machine, stepId) => {
  for (const [value, state] of Object.entries(machine.config.states)) {
    if (state.meta && state.meta.stepId === stepId) {
      return machine.resolveState({ ...machine.initialState, value });
    }
  }

  return machine.initialState;
};

export const Wizard = ({ machine, stepId, navigateToStep, ...props }) => {
  const [currentState, send] = useMachine(machine, {
    ...machine.config,
    state: getInitialState(machine, stepId),
    actions: {
      updateRoute: (_context, _event, { state }) => {
        const meta = mergeMeta(state.meta);
        if (meta.stepId && meta.stepId !== stepId) {
          navigateToStep(meta.stepId);
        }
      },
    },
  });

  const StepComponent = useMemo(
    () => mergeMeta(currentState.meta).Component || null,
    // TODO check: is it correct that the dependency is currentState.value
    // but the hook only processes currentState.meta?
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentState.value],
  );

  return StepComponent ? (
    <StepComponent send={send} {...props} />
  ) : (
    null
  );
};
