import styled, { css } from 'styled-components';
import { darken, transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Button styles, they're exported because ButtonLink is dependent */

export const smallButtonStyles = css`
  font-size: 12px;
  padding: 7px 12px;
`;

export const mediumButtonStyles = css`
  font-size: 14px;
  padding: 10px 14px;
`;

export const baseButtonStyles = css`
  border-radius: ${props => props.theme.border.radii.regular};
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  ${props => props.small ? smallButtonStyles : mediumButtonStyles}

  &[disabled] {
    cursor: auto;
  }
`;

export const solidButtonStyles = css`
  background-color: ${props => props.secondary ? props.theme.colors.lightGray : props.theme.colors.primary};
  color: ${props => props.secondary ? props.theme.colors.text : props.theme.colors.white};
  border: 0;

  &:hover:not([disabled]) {
    background-color: ${props => darken(props.theme.darken.dark, props.secondary ? props.theme.colors.lightGray : props.theme.colors.primary)};
  }

  &[disabled] {
    color: ${props => props.secondary ? transparentize(0.5, props.theme.colors.text) : props.theme.colors.white};
    background-color: ${props => transparentize(0.5, props.secondary ? props.theme.colors.lightGray : props.theme.colors.primary)};
  }
`;

export const outlineButtonStyles = css`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.gray};
  color: ${props => props.theme.colors.gray};
  background-color: ${props => props.theme.colors.white};

  &:hover:not([disabled]) {
    background-color: ${props => props.theme.colors.gray};
    color: ${props => props.theme.colors.white};
  }
`;

const ButtonBase = styled.button`
  ${baseButtonStyles}
`;

const SolidButton = styled(ButtonBase)`
  ${solidButtonStyles}
`;

const OutlineButton = styled(ButtonBase)`
  ${outlineButtonStyles}
`;

export const Label = styled.span`
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  color: inherit;
`;

const Button = ({ outline, children, iconL, iconR, ...props }) => {
  const ButtonComponent = outline ? OutlineButton : SolidButton;

  return (
    <ButtonComponent {...props}>
      {iconL && <FontAwesomeIcon icon={iconL} />}
      {children && <Label>{children}</Label>}
      {iconR && <FontAwesomeIcon icon={iconR} />}
    </ButtonComponent>
  );
};

export default Button;
