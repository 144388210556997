import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { Paragraph, ExternalLink, ContinueButton, SecondaryButton, ButtonRow } from './updateProductTags';

const Medium = styled.span`
  font-weight: 500;
`;

export const UpdateProductTagsStartStep = ({ send, company }) => {
  const { t } = useTranslation('onboarding');
  const hasSelectedTags = !isEmpty(company.productsAndServices);

  return (
    <div>
      <Paragraph>
        <Trans i18nKey="updateProductTags.unspscCodes" t={t}>
          {' '}
          <ExternalLink href="https://www.unspsc.org/" target="_blank" />
          {' '}
        </Trans>
      </Paragraph>
      <Paragraph>
        <Trans
          i18nKey="updateProductTags.existingSelectedTags"
          t={t}
          values={{ companyName: company.name }}
        >
          {' '}
          <Medium />
          {' '}
        </Trans>
      </Paragraph>
      <Paragraph>
        {t('updateProductTags.shouldSelectNewTags')}
      </Paragraph>
      <ButtonRow>
        <div>
          <SecondaryButton
            text={t('general.remindMeLater')}
            onClick={() => send('REMIND_ME')}
            disabled={hasSelectedTags}
          />
          <SecondaryButton
            text={t('general.doNotRemindAgain')}
            onClick={() => send('DONT_REMIND_ME')}
            disabled={hasSelectedTags}
          />
        </div>
        <ContinueButton
          text={t('updateProductTags.reviewAndUpdateTags')}
          onClick={() => send('NEXT_STEP')}
        />
      </ButtonRow>
      {hasSelectedTags && (
        <Paragraph style={{ marginTop: 30 }}>
          <FontAwesomeIcon icon="info-circle" />{' '}
          {t('updateProductTags.youHaveAlreadySelected')}
        </Paragraph>
      )}
    </div>
  );
};
