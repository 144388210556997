import { PureComponent } from 'react';
import { noop } from 'lodash';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import ErrorMessage from './ErrorMessage';

const transitionStyles = css`
  transition: all 0.3s ease-in-out;
`;

const FloatingLabelInput = styled.div`
  width: 100%;
`;

const FloatingLabelInputContainer = styled.div`
  position: relative;
  box-sizing: content-box;
  border: 1px solid ${props => props.theme.colors.lightGray};
  border-radius: ${props => props.theme.border.radii.sharp};
  font-size: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  ${transitionStyles}
`;

const requiredStyles = css`
  &::after {
    content: '*';
    color: ${props => props.theme.colors.danger};
    margin-left: 3px;
  }
`;

const FloatingLabel = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  top: 16px;
  left: 16px;
  transform-origin: left top;
  font-size: 1em;
  cursor: text;
  pointer-events: none;
  width: 90%;
  color: ${props => props.isFocused
    ? props.theme.colors.primary
    : props.isDisabled || !props.isActive
      ? props.theme.colors.disabled
      : props.theme.colors.darkGray2};
  transform: ${props => props.isActive ? 'translateY(-40%) scale(0.75)' : 'none'};
  font-weight: 400;

  ${transitionStyles}
  ${props => props.isRequired && requiredStyles}
`;

const floatingStyles = css`
  margin: 0;
  border: none;
  outline: none;
  font-size: 1em;
  padding: 25px 16px 8px;
  border-radius: 1px;
  color: ${props => props.theme.colors.text};

  &::placeholder {
    color: #9b9b9b;
    opacity: ${props => (props.isActive ? 1 : 0)};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
`;

const FloatingInput = styled.input`
  ${floatingStyles}

  :disabled {
    color: ${props => props.theme.colors.disabled};
    background-color: ${props => transparentize(0.75, props.theme.colors.lightGray)};
  }
`;

const FloatingSelect = styled.select`
  width: 100%;
  appearance: none;
  background-color: ${props => props.theme.colors.background};
  ${floatingStyles}
`;

const FloatingTextArea = styled.textarea`
  min-height: 250px;
  ${floatingStyles}
`;

const StatusBar = styled.div`
  position: absolute;
  left: -${props => props.theme.border.width};
  right: -${props => props.theme.border.width};
  bottom: -${props => props.theme.border.width};
  height: ${props => props.isVisible ? 2 : 0}px;
  ${transitionStyles}

  &::after {
    content: "";
    width: ${props => props.percent * 100}%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: ${props => props.hasError ? props.theme.colors.danger : props.theme.colors[props.color]};
    border-radius: 0 0 2px 2px;
  }
`;

const StatusText = styled.div`
  color: ${props => props.theme.colors[props.color]};
  font-size: 12px;
  margin-top: 5px;
`;

const IconRightContainer = styled.div`
  font-size: 16px;
  z-index: 2;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
`;

const HelperText = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.text};
`;

export default class TextInput extends PureComponent {
  constructor(props) {
    super(props);

    if (!props.id && !props.name) {
      throw new Error('expected id but none present');
    }

    this.state = {
      isActive: Boolean(props.value) && props.value.length > 0,
      isFocused: false,
    };

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onFocus(event) {
    const { type, onFocus = noop } = this.props;

    // Not a keyboard-based input
    if (type === 'select') return;

    this.setState({
      isActive: true,
      isFocused: true,
    });

    onFocus(event);
  }

  onBlur(event) {
    const { type, onBlur = noop } = this.props;

    // Not a keyboard-based input
    if (type === 'select') return;

    // If the element receiving focus is a link, then we don't update
    // the component.
    //
    // This is primarily to handle the issue of links not working when
    // an input is focused. It was taking two clicks to navigate with a
    // link because the first click would unfocus the input, and the second
    // would actually register and navigate. Typically the focused state
    // of the input wouldn't inpact the ability of a link to be clicked.
    //
    // I'm not entirely sure why this was happening--maybe it was a render
    // cycle thing--but this fix seems simple enough... hopefully it doesn't
    // have unintended consequences lol
    if (event.relatedTarget && event.relatedTarget.tagName === 'A') return;

    this.setState({
      isActive: event.target.value.length !== 0,
      isFocused: false,
    });

    onBlur(event);
  }

  onChange(event) {
    const { type, onChange = noop } = this.props;

    if (type === 'select') {
      // This is necessary to trigger floating label animation for select
      // boxes. Otherwise, the component never animates between active and
      // inactive states. No idea why.
      this.setState({
        isActive: event.target.value.length !== 0,
      });
    }

    onChange(event);
  }

  getInputComponent() {
    const { type } = this.props;

    switch (type) {
      case 'textarea': return FloatingTextArea;
      case 'select': return FloatingSelect;
      default: return FloatingInput;
    }
  }

  getIconRight() {
    const { type, iconR } = this.props;

    if (iconR) {
      return iconR;
    } else if (type === 'select') {
      return <FontAwesomeIcon icon="caret-down" />;
    } else {
      return null;
    }
  }

  render() {
    const {
      id,
      label,
      onBlur,
      onFocus,
      onChange,
      type,
      refs,
      error,
      style,
      isRequired,
      helperText,
      disabled,
      children,
      statusBarLabel,
      statusBarPercent = 1,
      statusBarColor = 'primary',
      ...otherProps
    } = this.props;

    const { isActive, isFocused } = this.state;

    const hasError = Boolean(error);
    const Component = this.getInputComponent();
    const iconR = this.getIconRight();

    return (
      <FloatingLabelInput style={style}>
        <FloatingLabelInputContainer isActive={isActive}>
          <FloatingLabel
            htmlFor={id}
            isActive={isActive}
            isFocused={isFocused}
            isRequired={isRequired}
            isDisabled={disabled}
          >
            {label}
          </FloatingLabel>
          <Component
            isActive={isActive}
            id={id}
            type={type}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onChange={this.onChange}
            ref={refs}
            disabled={disabled}
            {...otherProps}
          >
            {children}
          </Component>
          {iconR && (
            <IconRightContainer>{iconR}</IconRightContainer>
          )}
          <StatusBar
            isVisible={isFocused || hasError}
            percent={statusBarPercent}
            hasError={hasError}
            color={statusBarColor}
          />
        </FloatingLabelInputContainer>
        <StatusText color={statusBarColor}>{statusBarLabel}</StatusText>
        {!error && <HelperText>{helperText}</HelperText>}
        <ErrorMessage error={error} />
      </FloatingLabelInput>
    );
  }
}
