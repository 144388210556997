import { useState, useEffect } from 'react';

// A hook that immediately disables a redirection flag once it's
// been toggled on (so as to only remain enabled for a single
// render cycle)
//
// This is only useful when redirecting to a different route that
// uses the *same* component -- like the Wizard -- because the flag
// persists across renders since component doesn't get unmounted)
const useRedirection = () => {
  const [shouldRedirect, setRedirect] = useState(false);

  const redirect = () => setRedirect(true);

  // We immediately want to toggle off redirection after it's completed
  useEffect(
    () => {
      if (shouldRedirect) {
        setRedirect(false);
      }
    },
    [shouldRedirect],
  );

  return [shouldRedirect, redirect];
};

export default useRedirection;
