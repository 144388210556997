import * as React from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { isEqual, map } from 'lodash';
import { callAll } from '@deepstream/utils/callAll';
import { Button, CancelButton, SaveButton } from '@deepstream/ui-kit/elements/button/Button';
import { ModalProps, Modal, ModalHeader, ModalBody, ModalFooter } from '@deepstream/ui-kit/elements/popup/Modal';
import { useApi } from './api';
import { Company } from './ui/types';
import { HelpCenterLink } from './HelpCenterLink';
import { useMutation } from './useMutation';
import { useToaster } from './toast';
import { useProducts } from './useProducts';
import { FilteredProductsPanel } from './FilteredProductsList';

type EditModalProps =
  { company: Company; onCancel: any; onSave: any } &
  ModalProps;

export const EditProductsModal: React.FC<EditModalProps> = ({ company, onSave, onCancel, ...props }) => {
  const { t } = useTranslation();
  const api = useApi();
  const queryClient = useQueryClient();
  const toaster = useToaster();

  const initialSelectedProductIds = React.useMemo(
    () => map(company.productsAndServices, '_id'),
    [company.productsAndServices],
  );

  const {
    state,
    actions,
    showSearchResults,
    products,
    isProductsLoading,
    isProductsError,
    isSearching,
    isSearchError,
    refetchProducts,
    refetchSearch,
  } = useProducts({
    initialProducts: company.productsAndServices,
    selectedProductIds: initialSelectedProductIds,
  });

  const { productById, selectedProductIds, searchResultIds } = state;

  const [updateCompany, { isLoading: isSubmitting }] = useMutation(
    api.updateCompany,
    {
      onSuccess: callAll(
        () => toaster.success(t('company.toaster.profileUpdatedSuccess')),
        onSave,
      ),
      onError: () => toaster.error(t('company.toaster.profileUpdatedError')),
      onSettled: () => queryClient.invalidateQueries(['publicCompany', { companyId: company._id }]),
    },
  );

  const saveProducts = React.useCallback(
    async () => updateCompany({
      companyId: company._id,
      companyPatch: {
        productsAndServices: map(
          selectedProductIds,
          productId => productById[productId],
        ),
      },
    }),
    [selectedProductIds, productById, company, updateCompany],
  );

  const selectedProductsChanged = React.useMemo(
    () => !isEqual(selectedProductIds, map(company.productsAndServices, '_id')),
    [selectedProductIds, company],
  );

  return (
    <Modal
      onRequestClose={onCancel}
      style={{ content: { width: '900px' } }}
      {...props}
    >
      <ModalHeader onClose={onCancel}>
        {t('productsAndServices.productsAndServices')}
      </ModalHeader>
      <ModalBody>
        <Flex alignItems="center" justifyContent="space-between" height="40px" mb="12px">
          <Text>
            {!isProductsLoading ? (
              t('general.tagCount', { count: showSearchResults ? searchResultIds.length : products.length })
            ) : (
              null
            )}
          </Text>
          {selectedProductIds.length > 0 && (
            <Button small variant="secondary-outline" iconLeft="times" onClick={actions.deselectAll}>
              {t('general.deselectAll')}
            </Button>
          )}
        </Flex>
        <FilteredProductsPanel
          productsState={state}
          productsActions={actions}
          showSearchResults={showSearchResults}
          products={products}
          isProductsLoading={isProductsLoading}
          isProductsError={isProductsError}
          isSearching={isSearching}
          isSearchError={isSearchError}
          refetchProducts={refetchProducts}
          refetchSearch={refetchSearch}
        />
      </ModalBody>
      <ModalFooter justifyContent="space-between">
        <HelpCenterLink
          text={t('general.moreInformation')}
          path="/en/articles/6337679-search-for-new-suppliers-using-product-services-tags"
        />
        <Box>
          <CancelButton onClick={onCancel} mr={2} />
          <SaveButton
            onClick={saveProducts}
            disabled={isSubmitting || !selectedProductsChanged}
          />
        </Box>
      </ModalFooter>
    </Modal>
  );
};
