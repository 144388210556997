import { useMemo } from 'react';
import { getUserLocale } from '@deepstream/common/user-utils';
import { getRegionName } from '@deepstream/common';
import { useCountryCodes } from '@deepstream/ui/ui/countries';
import { useSession } from './hooks';

export const useCountryOptions = () => {
  const countryCodes = useCountryCodes();
  const { currentUser } = useSession();
  const locale = getUserLocale(currentUser);

  return useMemo(
    () => {
      if (!countryCodes) {
        return [];
      }

      const countryOptions = countryCodes.map(
        (code) => ({ label: getRegionName(code, locale), value: code }),
      );

      countryOptions.sort(
        (optionA, optionB) => optionA.label.localeCompare(optionB.label, locale),
      );

      return countryOptions;
    },
    [countryCodes, locale],
  );
};

const CountryOptions = () => {
  const countryOptions = useCountryOptions();

  return (
    <>
      {countryOptions.map(({ label, value }) => (
        <option key={value} value={value} style={{ fontSize: 14 }}>
          {label}
        </option>
      ))}
    </>
  );
};

export default CountryOptions;
