import { get } from 'lodash';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { getRegionName } from '@deepstream/common';
import { getUserLocale } from '@deepstream/common/user-utils';
import { CompanyVerificationStatus } from '@deepstream/common/onboarding/companyStatuses';

import DescriptionButton from './DescriptionButton';
import TruncatedList from './TruncatedList';
import { baseButtonStyles, solidButtonStyles } from './Button';
import * as Text from './text';
import { useApi } from './ApiProvider';
import { useSession } from './hooks';

const useListLabel = () => {
  const { t } = useTranslation('onboarding');

  return {
    accepted: {
      text: t('verification.accepted'),
      icon: 'check-circle',
      color: 'success',
    },
    rejected: {
      text: t('verification.rejected'),
      icon: 'times-circle',
      color: 'danger',
    },
    pending: {
      text: t('verification.pendingVerification'),
      icon: 'spinner',
      color: 'warning',
    },
  };
};

const ButtonAnchor = styled.a`
  ${baseButtonStyles}
  ${solidButtonStyles}
`;

const CompanyListTitle = styled.div`
  color: ${props => props.theme.colors[props.color]};
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 32px;
`;

const AdminListContainer = styled.div`
  margin-top: 6px;
`;

const CompanyAction = ({ company }) => {
  const { t } = useTranslation('onboarding');

  switch (company.verificationStatus) {
    case CompanyVerificationStatus.PENDING_COMPANY_VERIFICATION: {
      return (
        <Text.Normal style={{ textAlign: 'end' }}>{t('verification.waitingForCompanyAdmin')}</Text.Normal>
      );
    }
    case CompanyVerificationStatus.PENDING_INTERNAL_VERIFICATION: {
      return (
        <Text.Subtle style={{ textAlign: 'end' }}>{t('verification.waitingForDeepStream')}</Text.Subtle>
      );
    }
    case CompanyVerificationStatus.ACCEPTED: {
      return (
        <ButtonAnchor
          href={`${process.env.NX_APP_URL}/network/${company._id}/dashboard`}
          style={{ display: 'block' }}
          data-test="dashboard"
        >
          {t('general.goToDashboard')} <FontAwesomeIcon icon="arrow-right" style={{ marginLeft: 4 }} />
        </ButtonAnchor>
      );
    }
    default: {
      return null;
    }
  }
};

const AdminList = ({ company }) => {
  const api = useApi();
  const [users, setUsers] = useState([]);

  useEffect(
    () => {
      api.getCompanyAdmins(company._id).then((users) => { setUsers(users.map(u => u.email)); });
    },
    [api, company._id],
  );

  return (
    <AdminListContainer>
      <TruncatedList list={users} />
    </AdminListContainer>
  );
};

const isCompanyPendingVerification = (company) => (
  company.verificationStatus === CompanyVerificationStatus.PENDING_COMPANY_VERIFICATION
);

const CompanyList = ({ type, companies = [] }) => {
  const listLabel = useListLabel();
  const { t } = useTranslation('general');
  const session = useSession();
  const locale = getUserLocale(session.currentUser);

  if (companies.length === 0) {
    return null;
  }

  const list = listLabel[type];

  return (
    <div>
      <CompanyListTitle color={list.color}>
        <FontAwesomeIcon icon={list.icon} style={{ marginRight: 10 }} />
        {list.text}
      </CompanyListTitle>
      {companies.map(company => (
        <DescriptionButton
          disabled
          key={company._id}
          label={company.name}
          description={(
            <>
              <FontAwesomeIcon icon="map-marker-alt" style={{ marginRight: 7 }} />
              {getRegionName(get(company, 'address.country'), locale, t('unknown'))}
            </>
          )}
          imageUrl={`${process.env.NX_API_URL}/download/logo/${company._id}`}
        >
          <CompanyAction company={company} />
          {isCompanyPendingVerification(company) ? (
            <AdminList company={company} />
          ) : null }
        </DescriptionButton>
      ))}
    </div>
  );
};

export default CompanyList;
