import { useEffect, useMemo, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useIntercom } from 'react-use-intercom';
import i18next from 'i18next';
import { getLocaleCookie, setLocaleCookie } from '@deepstream/ui/cookie';

import { DEFAULT_LOCALE } from '@deepstream/common/user-utils';

import { useApi } from '../ApiProvider';
import * as userUtils from '../userUtils';
import { useAuth } from '../auth/getAuthHook';

const useSession = () => {
  const intercom = useIntercom();
  const api = useApi();
  const auth = useAuth();

  const {
    data: currentUser,
    isSuccess,
    isLoading,
    remove: removeCurrentUserCache,
  } = useQuery(
    ['me'],
    () => api.getMe(),
    {
      enabled: auth.isAuthenticated,
      onSuccess: (user) => {
        intercom.boot({
          userId: user._id,
          email: user.email,
          name: user.name,
          phone: user.phoneNumber,
          userHash: user.hashedUserId,
        });
      },
    },
  );
  useEffect(() => {
    let locale;
    if (currentUser) {
      locale = currentUser.preferences?.locale || DEFAULT_LOCALE;
      setLocaleCookie(locale);
    } else {
      locale = getLocaleCookie() || DEFAULT_LOCALE;
    }
    i18next.changeLanguage(locale);
    document.documentElement.setAttribute('lang', locale);
  }, [currentUser]);

  const { hasOnlyPendingMemberships, hasSomeVerifiedMemberships } = useMemo(
    () => ({
      hasOnlyPendingMemberships: currentUser ? userUtils.hasOnlyPendingMemberships(currentUser) : false,
      hasSomeVerifiedMemberships: currentUser ? userUtils.hasSomeVerifiedMemberships(currentUser) : false,
    }),
    [currentUser],
  );

  const logout = useCallback(
    () => {
      intercom.shutdown();

      auth.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth],
  );

  const isAuthenticated = useMemo(
    () => auth.isAuthenticated && isSuccess && Boolean(currentUser),
    [auth, isSuccess, currentUser],
  );

  useEffect(
    () => {
      if (!auth.isLoading && !auth.isAuthenticated) {
        removeCurrentUserCache();

        intercom.boot({
          appId: process.env.NX_INTERCOM_APP_ID,
          apiBase: process.env.NX_INTERCOM_BASE_URL,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth, removeCurrentUserCache],
  );

  return {
    ...auth,
    isAuthenticated: isAuthenticated && isSuccess && Boolean(currentUser),
    isAuthenticating: auth.isLoading || isLoading,
    currentUser,
    hasOnlyPendingMemberships,
    hasSomeVerifiedMemberships,
    logout,
  };
};

export default useSession;
