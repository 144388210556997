import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Heading } from './text';
import { Page } from './Layout';
import ErrorMessage from './ErrorMessage';
import ButtonLink from './ButtonLink';

const Forbidden = () => {
  const { t } = useTranslation('onboarding');

  return (
    <Page wide title={t('forbidden.accessDenied')}>
      <Heading>
        {t('forbidden.accessDenied')}
      </Heading>
      <ErrorMessage panel error={t('forbidden.noPermission')} />
      <ButtonLink
        to="/verification"
      >
        {t('forbidden.goToMyCompanies')} <FontAwesomeIcon icon="arrow-right" style={{ marginLeft: 4 }} />
      </ButtonLink>
    </Page>
  );
};

export default Forbidden;
