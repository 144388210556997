import { IntercomProvider as Intercom } from 'react-use-intercom';

export const IntercomProvider = ({ children }) => (
  <Intercom
    appId={process.env.NX_INTERCOM_APP_ID}
    apiBase={process.env.NX_INTERCOM_BASE_URL}
  >
    {children}
  </Intercom>
);
