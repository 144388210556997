import { useTranslation } from 'react-i18next';

const companySizes = [
  { label: '1-10', value: '1-10' },
  { label: '11-50', value: '11-50' },
  { label: '51-200', value: '51-200' },
  { label: '201-500', value: '201-500' },
  { label: '501-1000', value: '501-1000' },
  { label: '1001-5000', value: '1001-5000' },
  { label: '5001-10,000', value: '5001-10000' },
  { label: '10,001+', value: '10001' },
];

const CompanySizeOptions = () => {
  const { t } = useTranslation('onboarding');

  return (
    <>
      {companySizes.map(({ label, value }) => (
        <option key={value} value={value}>
          {label} {t('general.employee_other')}
        </option>
      ))}
    </>
  );
};

export default CompanySizeOptions;
