import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faMapMarkerAlt,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faArrowLeft,
  faArrowRight,
  faInfoCircle,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCamera,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faSpinner,
  faMapMarkerAlt,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faExclamationCircle,
  faArrowLeft,
  faArrowRight,
  faEye,
  faEyeSlash,
  faCaretDown,
  faCamera,
  faExclamationTriangle,
);
