import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Cell } from './Layout';
import Field from './Field';
import DeviceContext from './DeviceContext';
import CountryOptions from './CountryOptions';
import EmptyOption from './EmptyOption';

const AddressFields = () => {
  const { isExtraLarge, isLarge, isMedium } = useContext(DeviceContext);
  const { t } = useTranslation('onboarding');

  const cityField = (
    <Field label={t('general.city')} name="address.city" type="text" isRequired />
  );

  const stateField = (
    <Field label={t('createCompany.stateProvinceRegion')} name="address.state" type="text" />
  );

  const postcodeField = (
    <Field label={t('createCompany.zipPostcode')} name="address.postcode" type="text" isRequired />
  );

  const lineOneField = (
    <Field label={t('createCompany.addressLineOne')} name="address.lineOne" type="text" isRequired />
  );

  const lineTwoField = (
    <Field label={t('createCompany.addressLineTwo')} name="address.lineTwo" type="text" />
  );

  const countryField = (
    <Field label={t('general.country')} name="address.country" type="select" isRequired>
      <EmptyOption />
      <CountryOptions />
    </Field>
  );

  return (
    <>
      {lineOneField}
      {lineTwoField}
      {isExtraLarge || isLarge || isMedium ? (
        <Row>
          <Cell style={{ flex: 2, marginRight: 30 }}>{cityField}</Cell>
          <Cell style={{ flex: 1, marginRight: 30 }}>{stateField}</Cell>
          <Cell style={{ flex: 1 }}>{postcodeField}</Cell>
        </Row>
      ) : (
        <>
          {cityField}
          {stateField}
          {postcodeField}
        </>
      )}
      {countryField}
    </>
  );
};

export default AddressFields;
