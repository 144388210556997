import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const DeepStreamDarkLogo = () => {
  const theme = useContext(ThemeContext);
  const fillColor = theme.colors.text;

  return (
    <svg width="134" height="25" viewBox="0 0 134 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.7967 3.33868C29.1371 3.33868 32.0348 5.91493 32.0348 10.9456C32.0348 15.9762 29.1366 18.553 24.7967 18.553H19.0178V3.33868H24.7967ZM24.5025 16.5843C27.7765 16.5843 29.6475 14.635 29.6475 10.9488C29.6475 7.26262 27.7765 5.3079 24.5025 5.3079H21.3617V16.5843H24.5025Z" fill={fillColor} />
      <path d="M43.5155 15.2801C43.0024 16.8999 41.637 18.8085 38.3308 18.8085C35.1669 18.8085 33.0388 16.4469 33.0388 12.9491C33.0388 9.41111 35.0853 7.05063 38.3099 7.05063C41.5758 7.05063 43.8714 9.43151 43.5816 13.4531H35.2383C35.3022 15.5028 36.4658 17.0491 38.3308 17.0491C40.277 17.0491 41.0359 15.8147 41.3171 15.0096L43.5155 15.2801ZM38.3094 8.8116C36.4244 8.8116 35.4487 10.2334 35.2791 11.7947H41.381C41.2114 10.2334 40.2362 8.8116 38.3099 8.8116H38.3094Z" fill={fillColor} />
      <path d="M55.1139 15.2801C54.6008 16.8999 53.2354 18.8085 49.9287 18.8085C46.7653 18.8085 44.6372 16.4469 44.6372 12.9491C44.6372 9.41111 46.6837 7.05063 49.9083 7.05063C53.1742 7.05063 55.4698 9.43151 55.1799 13.4531H46.8367C46.9005 15.5028 48.0641 17.0491 49.9287 17.0491C51.8754 17.0491 52.6343 15.8147 52.9155 15.0096L55.1139 15.2801ZM49.9078 8.8116C48.0228 8.8116 47.0471 10.2334 46.8775 11.7947H52.9794C52.8098 10.2334 51.8346 8.8116 49.9083 8.8116H49.9078Z" fill={fillColor} />
      <path d="M62.0983 18.8085C60.5381 18.8085 59.3283 18.2224 58.7111 17.244H58.6279V22.4737H56.4504V7.30611H58.5232V8.75954H58.6097C59.2274 7.67859 60.4984 7.05063 62.1015 7.05063C65.0819 7.05063 67.2095 9.39072 67.2095 12.9293C67.2095 16.4679 65.0792 18.8085 62.0983 18.8085ZM58.4819 12.9293C58.4819 15.4825 59.7539 17.047 61.7226 17.047C63.7181 17.047 64.9875 15.4825 64.9875 12.9293C64.9875 10.3761 63.7181 8.81106 61.7226 8.81106C59.7539 8.8116 58.4819 10.3761 58.4819 12.9293Z" fill={fillColor} />
      <path d="M77.9313 7.72311C77.5272 5.80918 76.1263 4.88549 74.2049 4.88549C72.2668 4.88549 71.0646 5.8768 71.0646 7.25241C71.0646 8.55717 72.1096 8.99406 73.4852 9.31662L76.1049 9.91775C78.4176 10.4883 80.6369 11.0985 80.6369 14.4203C80.6369 17.398 77.985 18.89 74.733 18.89C71.042 18.89 68.6853 17.1253 68.147 13.8664L70.4736 13.6345C70.8021 16.1861 72.6785 17.0024 74.7744 17.0024C76.8316 17.0024 78.2517 16.0551 78.2517 14.4831C78.2517 12.8767 77.0549 12.4784 75.4522 12.0941L72.6715 11.4136C70.6856 10.9236 68.6746 10.1314 68.6746 7.31521C68.6746 4.68529 71.0694 3 74.2232 3C77.5701 3 79.5334 4.66382 80.2725 7.53687L77.9313 7.72311Z" fill={fillColor} />
      <path d="M90.0708 9.01124H90.154C90.7101 7.92331 91.893 7.05115 93.4967 7.05115C93.8318 7.05223 94.1658 7.08821 94.4934 7.15849V9.25169C94.1283 9.1979 93.76 9.16974 93.391 9.16742C91.5479 9.16742 90.175 10.0659 90.175 12.5826V18.553H87.998V7.30609H90.0703L90.0708 9.01124Z" fill={fillColor} />
      <path d="M104.948 15.2801C104.435 16.8999 103.07 18.8085 99.763 18.8085C96.5963 18.8085 94.4709 16.4469 94.4709 12.9491C94.4709 9.41111 96.5174 7.05063 99.7426 7.05063C103.008 7.05063 105.304 9.43151 105.014 13.4531H96.6704C96.7343 15.5028 97.8979 17.0491 99.763 17.0491C101.71 17.0491 102.468 15.8147 102.75 15.0096L104.948 15.2801ZM99.742 8.8116C97.8571 8.8116 96.8813 10.2334 96.7123 11.7947H102.814C102.644 10.2334 101.668 8.8116 99.7426 8.8116H99.742Z" fill={fillColor} />
      <path d="M115.795 16.8554C115.971 16.8551 116.145 16.8261 116.311 16.7695V18.5525C116.122 18.6163 115.596 18.6598 115.173 18.6598C114.121 18.6598 113.406 18.2889 113.182 17.3035H113.098C112.326 18.3845 110.781 18.809 109.502 18.809C107.548 18.809 105.893 17.5305 105.893 15.4481C105.893 13.0141 107.575 12.1301 110.512 11.8301C112.551 11.6116 113.081 11.2354 113.081 10.5259C113.081 9.52702 112.476 8.83251 110.864 8.83251C109.751 8.83251 108.676 9.40304 108.288 10.7271L106.299 10.2655C106.771 8.25178 108.58 7.05115 110.929 7.05115C114.128 7.05115 115.259 8.60065 115.259 10.4835V16.1893C115.259 16.7475 115.515 16.8554 115.795 16.8554V16.8554ZM113.081 12.7763C112.74 13.1617 111.477 13.3522 110.283 13.503C109.15 13.6544 108.113 14.2072 108.113 15.4443C108.113 16.4802 108.803 17.0856 109.921 17.0856C111.33 17.0856 113.081 16.2204 113.081 14.1149V12.7763Z" fill={fillColor} />
      <path d="M129.969 7.05008C131.853 7.05008 133.735 8.25072 133.735 11.0932V18.5535H131.558V11.7485C131.558 9.63115 130.73 8.87116 129.265 8.87116C127.959 8.87116 126.708 10.0519 126.708 12.3598V18.553H124.531V11.7485C124.531 9.63062 123.703 8.87116 122.238 8.87116C120.929 8.87116 119.681 10.0595 119.681 12.3673V18.553H117.504V7.30609H119.576V8.8089H119.66C120.519 7.56103 121.693 7.05169 122.942 7.05169C124.23 7.05169 125.527 7.61202 126.204 8.89424C127.209 7.68502 128.321 7.05169 129.969 7.05169L129.969 7.05008Z" fill={fillColor} />
      <path d="M86.5188 8.94202V7.30611H84.0564V5.20325H81.8816V14.6833C81.8827 15.7093 82.2908 16.6929 83.0163 17.4183C83.7417 18.1438 84.7254 18.5519 85.7513 18.553H86.4228V16.3756H85.7513C85.3026 16.3751 84.8723 16.1967 84.555 15.8795C84.2376 15.5622 84.0591 15.132 84.0585 14.6833V8.94041L86.5188 8.94202Z" fill={fillColor} />
      <path d="M15.2138 7.90293H8.74794V3.33868H6.46582V10.185H15.2138V7.90293Z" fill={fillColor} />
      <path d="M0 13.9888H6.46583V18.553H8.74795V11.7066H0V13.9888Z" fill={fillColor} />
    </svg>
  );
};

export default DeepStreamDarkLogo;
