import { createContext } from 'react';
import noop from 'lodash/noop';

export const initialContext = {
  isAuthenticated: false,
  isLoading: false,
  token: '',
  error: '',
  login: noop,
  logout: noop,
  getAccessTokenSilently: () => Promise.resolve(''),
};

export const SimpleAuthContext = createContext(initialContext);
