import { useMemo } from 'react';
import { Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import { isEqual, map } from 'lodash';
import { ProductTag } from '@deepstream/common/products';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { Button } from '@deepstream/ui-kit/elements/button/Button';
import { PanelText } from '@deepstream/ui-kit/elements/Panel';
import { useProducts } from './useProducts';
import { FilteredProductsPanel } from './FilteredProductsList';

export const EditProductsPanel = ({
  value,
  onChange,
}: {
  /**
   * The externally stored selection of product and service tags.
   */
  value: ProductTag[];
  /**
   * Called whenever the selection of product and service tags has been
   * changed. The `newValue` parameter holds the new selection of product
   * and service tags, ordered ascending by ID.
   */
  onChange: (newValue: ProductTag[]) => void;
}) => {
  const { t } = useTranslation();

  const initialState = useMemo(
    () => ({
      initialProducts: value,
      selectedProductIds: map(value, '_id'),
    }),
    [value],
  );

  const {
    state,
    actions,
    showSearchResults,
    products,
    isProductsLoading,
    isProductsError,
    isSearching,
    isSearchError,
    refetchProducts,
    refetchSearch,
  } = useProducts(initialState);

  const { productById, selectedProductIds, searchResultIds } = state;

  useWatchValue(
    selectedProductIds,
    (productIds) => {
      const newValue = map(
        productIds,
        productId => productById[productId],
      );

      onChange(newValue);
    },
    isEqual,
  );

  return (
    <PanelText p={3} color="darkGray">
      <Flex alignItems="center" justifyContent="space-between" height="40px" mb="12px">
        <Text>
          {!isProductsLoading ? (
            t('general.tagCount', { count: showSearchResults ? searchResultIds.length : products.length })
          ) : (
            null
          )}
        </Text>
        {selectedProductIds.length > 0 && (
          <Button small variant="secondary-outline" iconLeft="times" onClick={actions.deselectAll}>
            {t('general.deselectAll')}
          </Button>
        )}
      </Flex>
      <FilteredProductsPanel
        productsState={state}
        productsActions={actions}
        showSearchResults={showSearchResults}
        products={products}
        isProductsLoading={isProductsLoading}
        isProductsError={isProductsError}
        isSearching={isSearching}
        isSearchError={isSearchError}
        refetchProducts={refetchProducts}
        refetchSearch={refetchSearch}
      />
    </PanelText>
  );
};
