// initSentry needs to be the first import
import './initSentry';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { ensureIntlListFormat } from '@deepstream/ui/polyfill/ensureIntlListFormat';
import { initReactTranslations } from '@deepstream/ui/i18n';
import App from './App';

ensureIntlListFormat()
  .then(initReactTranslations)
  .then(() => {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<App />);
  })
  .catch(err => Sentry.captureException(err));
