import * as Sentry from '@sentry/react';
import { compact } from 'lodash';
import { Environment } from '@deepstream/environment';

Sentry.init({
  dsn: process.env.NX_ONBOARDING_SENTRY_DSN,
  environment: process.env.NX_SENTRY_ENV,
  release: process.env.NX_RELEASE_REV,
  // Log errors only from scripts from our domain and
  // ignore 3rd parties scripts like Google Analytics
  // E.g matches https://deepstreamtech.com https://app.deepstreamtech.com https://app.demo.deepstreamtech.com
  allowUrls: [
    new RegExp('localhost|https?://([a-z0-9]+[.])*deepstreamtech[.]com'), // eslint-disable-line prefer-regex-literals
  ],
  ignoreErrors: [
    'Loading chunk',
    'Default values in destructuring declarations',
    'Object Not Found Matching Id',
  ],
  ...process.env.NX_ENV === Environment.DEV
    ? {
      beforeSend: (event) => {
        // eslint-disable-next-line no-console
        console.log(
          '[Sentry]',
          ...event.exception.values.flatMap(item => compact([
            `${item.type}: "${item.value}"`,
            event.contexts?.[item.type],
          ])),
        );

        // return null to prevent sending event to Sentry
        return null;
      },
    }
    : null,
});

// Not sure why below code is necessary to get the integrations
// to work in onboarding. In the app project, we don't need this
// workaround.
for (const integration of Sentry.getDefaultIntegrations()) {
  Sentry.addIntegration(integration);
}
Sentry.addIntegration(Sentry.extraErrorDataIntegration());
