import { useContext } from 'react';
import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { keys, omit } from 'lodash';

import { SegmentContext } from '@deepstream/common';

import { useIdle } from '../../hooks/useIdle';
import { useCurrentCompanyId } from '../../currentCompanyId';

const SESSION_UPDATE_INTERVAL = 60000;
// Avoid calling post session too often, not to create waterfall sessions due to fast changing context.
const SESSION_UPDATE_THROTTLE = 1000;

type SessionContextValue = {
  updateContext: (partialContext: SegmentContext) => (() => void);
};

const defaultContextValue = {
  updateContext: () => () => {},
};

const ActiveSessionContext = React.createContext<SessionContextValue>(defaultContextValue);

interface Props {
  children: React.ReactNode;
  useApiHook: () => ({ postActiveSession });
  appName: 'app' | 'onboarding';
}

export const DisabledActiveSessionProvider = ({ children }: Props) => (
  <ActiveSessionContext.Provider value={defaultContextValue}>
    {children}
  </ActiveSessionContext.Provider>
);

export const ActiveSessionProvider = ({ children, useApiHook, appName }: Props) => {
  const [context, setContext] = React.useState<SegmentContext>({});
  const api = useApiHook();
  const isIdle = useIdle();
  const companyId = useCurrentCompanyId() || undefined;

  const [postActiveSession] = useDebouncedCallback(
    (context) => api.postActiveSession(context, companyId, appName),
    SESSION_UPDATE_THROTTLE,
  );

  const updateUserSession = React.useCallback(
    () => {
      if (isIdle) return;

      postActiveSession(context);
    },
    [isIdle, postActiveSession, context],
  );

  React.useEffect(
    () => {
      updateUserSession();

      const intervalId = window.setInterval(
        () => updateUserSession(),
        SESSION_UPDATE_INTERVAL,
      );

      return () => {
        window.clearInterval(intervalId);
      };
    },
    [updateUserSession],
  );

  const updateContext = React.useCallback(
    (partialContext: SegmentContext) => {
      setContext(context => ({
        ...context,
        ...partialContext,
      } as any));

      return () => {
        setContext(context => omit(context, keys(partialContext)) as SegmentContext);
      };
    },
    [],
  );

  const value = React.useMemo(
    () => ({
      context,
      updateContext,
    }),
    [context, updateContext],
  );

  return (
    <ActiveSessionContext.Provider value={value}>
      {children}
    </ActiveSessionContext.Provider>
  );
};

export const useActiveSession = () => {
  const context = useContext(ActiveSessionContext);

  if (!context) {
    throw new Error('ActiveSessionProvider should be present on all pages. Check why it is missing and add it.');
  }

  return context;
};

export const useTrackActiveSession = (context: SegmentContext) => {
  const { updateContext } = useActiveSession();

  React.useEffect(
    () => {
      if (!context) return;

      const clearContext = updateContext(context);

      return () => {
        clearContext();
      };
    },
    [context, updateContext],
  );
};
