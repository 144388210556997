import { Attachment } from './rfq-utils';

export enum TermsOfServiceStatus {
  DRAFT = 'draft',
  LIVE = 'live',
  REPLACED = 'replaced',
}

export type TermsOfService = {
  _id: string;
  readonly createdAt: Date;
  updatedAt: Date;
  version: number;
  html: string;
  changesDescription: string;
  status: TermsOfServiceStatus;
  attachments: Attachment[];
  setLiveAt?: Date;
};
