import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { baseButtonStyles, solidButtonStyles, outlineButtonStyles, Label } from './Button';

/* A react router Link that is styled like a button */

const ButtonLinkBase = styled(Link)`
  ${baseButtonStyles}
  display: inline-block;
  transition: all 0.2s ease-in-out;
`;

const SolidButtonLink = styled(ButtonLinkBase)`
  ${solidButtonStyles}
`;

const OutlineButtonLink = styled(ButtonLinkBase)`
  ${outlineButtonStyles}
`;

/* An <a> tag that is styled like a button */

const ButtonAnchor = styled.a`
  ${baseButtonStyles}
  display: inline-block;
  transition: all 0.2s ease-in-out;
`;

const SolidButtonAnchor = styled(ButtonAnchor)`
  ${solidButtonStyles}
`;

const OutlineButtonAnchor = styled(ButtonAnchor)`
  ${outlineButtonStyles}
`;

const ButtonLink = ({ outline, children, iconL, iconR, ...props }) => {
  // eslint-disable-next-line no-nested-ternary
  const ButtonLinkComponent = props.to
    ? (outline ? OutlineButtonLink : SolidButtonLink)
    : (outline ? OutlineButtonAnchor : SolidButtonAnchor);

  return (
    <ButtonLinkComponent {...props}>
      {iconL && <FontAwesomeIcon icon={iconL} />}
      <Label>{children}</Label>
      {iconR && <FontAwesomeIcon icon={iconR} />}
    </ButtonLinkComponent>
  );
};

export default ButtonLink;
