import { createMachine } from 'xstate';
import { UpdateProductTagsStartStep } from './UpdateProductTagsStartStep';
import { UpdateProductTagsEditStep } from './UpdateProductTagsEditStep';
import { UpdateProductTagsDoneStep } from './UpdateProductTagsDoneStep';
import { UpdateProductTagsRemindMeStep } from './UpdateProductTagsRemindMeStep';
import { UpdateProductTagsDontRemindMeStep } from './UpdateProductTagsDontRemindMeStep';

export const updateProductTagsMachine = createMachine({
  initial: 'start',
  states: {
    start: {
      entry: 'updateRoute',
      on: { NEXT_STEP: 'edit', REMIND_ME: 'remindMe', DONT_REMIND_ME: 'dontRemindMe' },
      meta: {
        stepId: 'start',
        Component: UpdateProductTagsStartStep,
      },
    },
    remindMe: {
      entry: 'updateRoute',
      on: { PREV_STEP: 'start' },
      meta: {
        stepId: 'remind-me-later',
        Component: UpdateProductTagsRemindMeStep,
      },
    },
    dontRemindMe: {
      entry: 'updateRoute',
      on: { PREV_STEP: 'start' },
      meta: {
        stepId: 'dont-remind-me-again',
        Component: UpdateProductTagsDontRemindMeStep,
      },
    },
    edit: {
      entry: 'updateRoute',
      on: { PREV_STEP: 'start', NEXT_STEP: 'done' },
      meta: {
        stepId: 'edit',
        Component: UpdateProductTagsEditStep,
      },
    },
    done: {
      entry: 'updateRoute',
      on: { PREV_STEP: 'edit' },
      meta: {
        stepId: 'done',
        Component: UpdateProductTagsDoneStep,
      },
    },
  },
});
