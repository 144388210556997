import { BasicChange, ChangeType } from '../rfq-utils/changeTypes';
import {
  BasicEvent, RequestCreatedEvent,
  DetailsChangedEvent, SendersChangedEvent, SettingsUpdatedEvent, RequestCurrencyChangedEvent,
  SpendAndSavingsChangedEvent, RecipientsUpdatedEvent,
} from '../rfq-utils/types';

interface TemplateMeta {
  name: string;
  createdBy: {
    _id: string;
    name: string;
  };
  createdAt: string;
  lastEditedBy: {
    _id: string;
    name: string;
  };
  lastEditedAt: string;
  fromRequest: string;
  status: string;
}

export enum TemplateEventType {
  TEMPLATE_CHANGED = 'template-changed',
  TEMPLATE_DELETED = 'template-deleted',
}

interface TemplateChangedEvent extends BasicEvent {
  type: TemplateEventType.TEMPLATE_CHANGED;
  companyId: string;
  changes: TemplateChange[];
}

interface TemplateDeletedEvent extends BasicEvent {
  type: TemplateEventType.TEMPLATE_DELETED;
}

export type TemplateEvent =
  | RequestCreatedEvent
  | RequestCurrencyChangedEvent
  | SpendAndSavingsChangedEvent
  | SettingsUpdatedEvent
  | DetailsChangedEvent
  | SendersChangedEvent
  | RecipientsUpdatedEvent
  | TemplateChangedEvent
  | TemplateDeletedEvent;

export interface Template {
  _id: string;
  meta: TemplateMeta;
  events: TemplateEvent[];
}

interface TemplateFieldChange extends BasicChange {
  type: ChangeType.TEMPLATE_FIELD_UPDATED;
  fieldName: string;
  fieldValue: string;
}

type TemplateChange = TemplateFieldChange;
