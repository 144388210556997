import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, Paragraph } from './text';
import SuggestedCompaniesList from './SuggestedCompaniesList';
import Input from './Input';
import * as Layout from './Layout';
import CreateCompanyLink from './CreateCompanyLink';
import { useApi } from './ApiProvider';

const FindCompany = () => {
  const [companyName, setCompanyName] = useState('');
  const [companies, setCompanies] = useState([]);
  const api = useApi();

  useEffect(() => {
    const findCompanies = async () => {
      api
        .findCompaniesAvailableToJoin(companyName)
        .then(setCompanies);
    };

    findCompanies();
  }, [api, companyName]);
  const { t } = useTranslation('onboarding');

  return (
    <Layout.Page wide title={t('findCompany.findYourCompany')}>
      <Heading>{t('findCompany.findYourCompany')}</Heading>
      <Paragraph>
        {t('findCompany.accessDeepstreamNetwork')}
      </Paragraph>
      <Input
        name="companyName"
        label={t('findCompany.whoDoYouWorkFor')}
        onChange={event => setCompanyName(event.target.value)}
        value={companyName}
        style={{ marginBottom: 20 }}
      />
      {Boolean(companies.length) && (
        <SuggestedCompaniesList companies={companies} />
      )}
      <CreateCompanyLink />
    </Layout.Page>
  );
};

export default FindCompany;
