import { merge } from 'lodash';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Heading, Paragraph } from './text';
import AutoSave from './AutoSave';
import WizardNav from './WizardNav';
import Title from './Title';
import ScrollToTop from './ScrollToTop';
import CompanyProfileFields from './CompanyProfileFields';
import Fieldset from './Fieldset';
import Legend from './Legend';
import Field from './Field';
import Checkbox from './Checkbox';
import { websiteRegExp } from './utils';

const initialValues = {
  name: '',
  web: '',
  email: '',
  telephone: '',
  employeesNb: '',
  number: '',
  registeredName: '',
  logo: '',
  address: {
    lineOne: '',
    lineTwo: '',
    postcode: '',
    city: '',
    state: '',
    country: '',
  },
  jobTitle: '',
  hasCompanyAuthorization: false,
};

/**
 * Warning: These are tightly coupled with the CreateCompanyWizard
 */

const RegisterCompany = ({ wizardState, stepIndex, numSteps, updateStep, prevStep, nextStep }) => {
  const { t } = useTranslation('onboarding');

  return (
    <div>
      <Title text={t('createCompany.registerCompany')} />
      <ScrollToTop />
      <Heading>
        {t('createCompany.registerYourCompany')}
      </Heading>
      <Paragraph style={{ marginBottom: 30 }}>
        {t('createCompany.goodNews')}
      </Paragraph>
      <Formik
        initialValues={merge({}, initialValues, wizardState)}
        validationSchema={
          yup.object().shape({
            name: yup.string().required(t('required', { ns: 'general' })),
            web: yup.string().matches(websiteRegExp, t('general.invalidWebsite'))
              .required(t('required', { ns: 'general' })),
            email: yup.string().email(t('general.invalidEmailAddress')).required(t('required', { ns: 'general' })),
            telephone: yup.string().notRequired(),
            employeesNb: yup.string().required(t('required', { ns: 'general' })),
            registeredName: yup.string().notRequired(),
            number: yup.string().notRequired(),
            logo: yup.string().notRequired(),
            address: yup.object().shape({
              lineOne: yup.string().required(t('required', { ns: 'general' })),
              lineTwo: yup.string().notRequired(),
              city: yup.string().required(t('required', { ns: 'general' })),
              state: yup.string().notRequired(),
              postcode: yup.string().required(t('required', { ns: 'general' })),
              country: yup.string().required(t('required', { ns: 'general' })),
            }),
            jobTitle: yup.string().required(t('required', { ns: 'general' })),
            hasCompanyAuthorization: yup.boolean().required(t('required', { ns: 'general' }))
              .oneOf([true], t('required', { ns: 'general' })),
          })
        }
        onSubmit={values => {
          updateStep({
            ...values,
            web: values.web.toLowerCase(),
          });
          nextStep();
        }}
        render={({ values, isSubmitting }) => (
          <Form>
            <CompanyProfileFields companyId={values._id} />
            <Fieldset>
              <Legend>{t('createCompany.yourPersonalInformation')}</Legend>
              <Field label={t('general.yourRole')} name="jobTitle" type="text" isRequired />
              <Checkbox
                label={t('createCompany.workForThisCompany')}
                name="hasCompanyAuthorization"
              />
            </Fieldset>
            <WizardNav
              stepIndex={stepIndex}
              numSteps={numSteps}
              disabled={isSubmitting}
              goBack={() => {
                updateStep(initialValues);
                prevStep();
              }}
            />
            <AutoSave
              debounceMs={2000}
              values={values}
              onSave={updateStep}
            />
          </Form>
        )}
      />
    </div>
  );
};

export default RegisterCompany;
