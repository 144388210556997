import { useEffect } from 'react';
import * as React from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import ReactModal from 'react-modal';
import { ReactQueryProvider } from '@deepstream/ui/ReactQueryProvider';
import { ActiveSessionProvider } from '@deepstream/ui/modules/ActiveSession/ActiveSessionContext';
import { FullScreenLoading } from '@deepstream/ui/ui/FullScreenLoading';
import SignUpSuccess from './SignUpSuccess';
import { Landing } from './Landing';
import { Login } from './Login';
import EmailVerified from './EmailVerified';
import FindCompany from './FindCompany';
import CreateCompanyWizard from './CreateCompanyWizard';
import { UpdateProductTagsWizard } from './UpdateProductTagsWizard';
import Verification from './Verification';
import JoinCompany from './JoinCompany';
import UpdateCompany from './UpdateCompany';
import Forbidden from './Forbidden';
import AuthContext from './AuthContext';
import DeviceContext from './DeviceContext';
import EmailVerificationError from './EmailVerificationError';
import { useSession, useDeviceSize } from './hooks';
import GlobalStyles from './GlobalStyles';
import onboardingTheme from './onboardingTheme';
import { IntercomProvider } from './IntercomProvider';
import { SystemFeatureFlagsProvider, useSystemFeatureFlags } from './systemFeatureFlags';
import { ApiProvider, useApi } from './ApiProvider';
import { CountriesProviderWrapper } from './CountriesProviderWrapper';
import { SignUp } from './SignUp';
import { LS_REDIRECT_KEY } from './apiClient';
import ChangePasswordSuccess from './ChangePasswordSuccess';
import { AuthProvider } from './auth/getAuthProvider';
import { IncompleteDataWizard } from './IncompleteDataWizard';
import { hasIncompleteData } from './userUtils';
import { Logout } from './local-auth/Logout';
import { isLocalAuth } from './auth/isLocalAuth';

// imports with side-effects
import './icon';

const UnauthenticatedRoutes = () => (
  <Switch>
    <Route exact path="/">
      {isLocalAuth() ? <Redirect to="/login" /> : <Landing />}
    </Route>
    <Route exact path="/logout"><Logout /></Route>
    <Route exact path="/email-verification-error" component={EmailVerificationError} />
    <Route exact path="/email-verified" component={EmailVerified} />
    <Route exact path="/signup" component={SignUp} />
    <Route exact path="/change-password/success" component={ChangePasswordSuccess} />
    <Route exact path="/callback" component={FullScreenLoading} />
    <Route exact path="/login" component={Login} />

    {/* Matches any other route */}
    <Route
      path="/"
      render={() => <Redirect to="/" />}
    />
  </Switch>
);

const UnverifiedRoutes = () => (
  <Switch>
    {/* Unauthenticated routes */}
    <Route exact path="/" component={Landing} />
    <Route exact path="/logout"><Logout /></Route>
    <Route exact path="/email-verification-error" component={EmailVerificationError} />
    <Route exact path="/email-verified" component={EmailVerified} />
    <Route exact path="/change-password/success" component={ChangePasswordSuccess} />
    <Route exact path="/callback" component={FullScreenLoading} />
    <Route exact path="/login" component={Login} />

    {/* Unverified routes */}
    <Route exact path="/signup/success" component={SignUpSuccess} />

    {/* Matches any other route */}
    <Route
      path="/"
      render={() => <Redirect to="/signup/success" />}
    />
  </Switch>
);

const IncompleteDataRoutes = () => (
  <Switch>
    {/* Unauthenticated routes */}
    <Route exact path="/" component={Landing} />
    <Route exact path="/logout"><Logout /></Route>
    <Route exact path="/email-verification-error" component={EmailVerificationError} />
    <Route exact path="/email-verified" component={EmailVerified} />
    <Route exact path="/change-password/success" component={ChangePasswordSuccess} />
    <Route exact path="/callback" component={FullScreenLoading} />
    <Route exact path="/login" component={Login} />

    {/* Unverified routes */}
    <Route exact path="/signup/success" component={SignUpSuccess} />

    {/* Incomplete data routes */}
    <Route exact path="/incomplete/:stepId?" component={IncompleteDataWizard} />

    {/* Matches any other route */}
    <Route
      path="/"
      render={() => <Redirect to="/incomplete" />}
    />
  </Switch>
);

const AllRoutes = () => (
  <Switch>
    {/* Unauthenticated routes */}
    <Route exact path="/" component={Landing} />
    <Route exact path="/logout"><Logout /></Route>
    <Route exact path="/email-verification-error" component={EmailVerificationError} />
    <Route exact path="/email-verified" component={EmailVerified} />
    <Route exact path="/change-password/success" component={ChangePasswordSuccess} />
    <Route exact path="/callback" component={FullScreenLoading} />
    <Route exact path="/login" component={Login} />

    {/* Unverified routes */}
    <Route exact path="/signup/success" component={SignUpSuccess} />

    {/* Incomplete data routes */}
    <Route exact path="/incomplete/:stepId?" component={IncompleteDataWizard} />

    {/* Verified routes */}
    <Route exact path="/forbidden" component={Forbidden} />
    <Route exact path="/find-company" component={FindCompany} />
    <Route exact path="/join-company/:companyId" component={JoinCompany} />
    <Route exact path="/verification" component={Verification} />
    <Route exact path="/update-company/:companyId" component={UpdateCompany} />
    <Route path="/create-company/:stepId?" component={CreateCompanyWizard} />
    <Route
      exact
      path="/company/:companyId/update-product-tags/:stepId?"
      component={UpdateProductTagsWizard}
    />
  </Switch>
);

const Routes = () => {
  const session = useSession();
  const location = useLocation();
  const intercom = useIntercom();
  const systemFeatureFlags = useSystemFeatureFlags();

  React.useEffect(
    () => {
      // We need to let Intercom know when the route has changed
      // https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
      intercom.update();
    },
    [intercom, location],
  );

  return (
    <AuthContext.Provider value={session}>
      {session.isAuthenticating ? (
        <FullScreenLoading />
      ) : !session.isAuthenticated ? (
        <UnauthenticatedRoutes />
      ) : (
        <ActiveSessionProvider appName="onboarding" useApiHook={useApi}>
          {!session.currentUser.verified ? (
            <UnverifiedRoutes />
          ) : systemFeatureFlags.requiredTermsAndNameEnabled && hasIncompleteData(session.currentUser) ? (
            <IncompleteDataRoutes />
          ) : (
            <AllRoutes />
          )}
        </ActiveSessionProvider>
      )}
    </AuthContext.Provider>
  );
};

const App = () => {
  const device = useDeviceSize();

  useEffect(() => {
    ReactModal.setAppElement('#root');

    // If the user is redirected to onboarding from the app or the admin panel,
    // we need to store the url he is coming from and redirect to that url after
    // a successful login.

    const { referrer } = window.document;

    const shouldRedirectAfterLogin = referrer &&
      (referrer.startsWith(process.env.NX_APP_URL) || referrer.startsWith(process.env.NX_ADMIN_URL));

    if (shouldRedirectAfterLogin) {
      localStorage.setItem(LS_REDIRECT_KEY, JSON.stringify({
        redirectURL: referrer,
        shouldBeAdmin: referrer.startsWith(process.env.NX_ADMIN_URL),
      }));
    }
  }, []);

  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <ApiProvider>
            <ReactQueryProvider>
              <ThemeProvider theme={onboardingTheme}>
                <SystemFeatureFlagsProvider>
                  <IntercomProvider>
                    <CountriesProviderWrapper>
                      <GlobalStyles />
                      <DeviceContext.Provider value={device}>
                        <React.Suspense fallback={null}>
                          <Routes />
                        </React.Suspense>
                      </DeviceContext.Provider>
                    </CountriesProviderWrapper>
                  </IntercomProvider>
                </SystemFeatureFlagsProvider>
              </ThemeProvider>
            </ReactQueryProvider>
          </ApiProvider>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
