import styled from 'styled-components';
import { camelCase } from 'lodash';
import { Field } from 'formik';
import { Row } from './Layout';
import ErrorMessage from './ErrorMessage';

const StyledLabel = styled.label`
  color: ${props => props.theme.colors.gray};
  font-size: 14px;
  cursor: pointer;
`;

const Checkbox = ({ label, name }) => (
  <Field id={camelCase(name)} name={name}>
    {({ field: { name, value, onChange, onBlur }, form: { touched, errors } }) => (
      <div>
        <StyledLabel htmlFor={camelCase(name)}>
          <Row align="center">
            <input
              name={name}
              id={camelCase(name)}
              type="checkbox"
              value={value}
              checked={value}
              onChange={onChange}
              onBlur={onBlur}
            />
            <span style={{ marginLeft: 5 }}>{label}</span>
          </Row>
        </StyledLabel>
        <ErrorMessage error={touched[name] && errors[name]} />
      </div>
    )}
  </Field>
);

export default Checkbox;
