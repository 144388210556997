import { useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHover } from '@deepstream/ui-kit/hooks/useHover';
import { useWatchValue } from '@deepstream/ui-kit/hooks/useWatchValue';
import { useTranslation } from 'react-i18next';

import ErrorMessage from './ErrorMessage';
import { useApi } from './ApiProvider';

const transitionStyles = css`
  transition: all 0.3s ease-in-out;
`;

const OuterContainer = styled.div`
  margin-bottom: 22px;
`;

const UploadContainer = styled.div`
  position: relative;
  border: 1px solid #D4D9E2;
  box-sizing: border-box;
  border-radius: 2px;
  padding-top: 11px;
  padding-bottom: 22px;
  padding-left: 22px;
  padding-right: 22px;
`;

const UploadLabel = styled.label`
  display: block;
  font-size: 12px;
  margin-bottom: 12px;
  color: ${props => props.theme.colors.text};

  &:after {
    visibility: ${props => props.isRequired ? 'visible' : 'hidden'};
    content: '*';
    color: ${props => props.theme.colors.danger};
    margin-left: 3px;
  }
`;

const BG_IMAGE_PADDING = '10px';

const UploadInner = styled.label`
  position: relative;
  color: ${props => props.theme.colors.text};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F7F9FB;
  border-radius: 10px;
  max-width: 100%;
  width: 136px;
  height: 136px;
  margin: auto;
  text-align: center;
  cursor: pointer;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 10px rgba(0, 0, 0, 0.2);

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;

  border: ${BG_IMAGE_PADDING} solid transparent;

  &:before {
    content: ' ';
    position: absolute;
    background: rgb(0, 0, 0, .3);
    border-radius: 10px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    margin: -${BG_IMAGE_PADDING};
  }

  &:hover:before {
    visibility: visible;
  }
`;

const AddLogo = styled.div`
  z-index: 2;
  pointer-events: none;
  color: ${props => props.hover ? props.theme.colors.white : 'inherit'};
`;

const StatusBar = styled.div`
  position: absolute;
  left: -1px;
  right: -1px;
  bottom: -1px;
  height: ${props => props.hasError ? 2 : 0}px;
  ${transitionStyles}

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: ${props => props.hasError ? props.theme.colors.danger : null};
    border-radius: 0 0 2px 2px;
  }
`;

const UploadLogo = ({ companyId, id, name, label, error, isRequired, value, onChange }) => {
  const api = useApi();
  const [ref, hover] = useHover();
  const [url, setUrl] = useState(value);
  const [uploadError, setUploadError] = useState(null);
  const { t } = useTranslation('onboarding');

  useWatchValue(
    url,
    url => onChange({ target: { id, name, value: url } }),
  );

  useWatchValue(
    value,
    value => {
      if (value !== url) {
        setUrl(value);
      }
    },
  );

  return (
    <OuterContainer>
      <UploadContainer>
        <UploadLabel isRequired={isRequired}>
          {label}
        </UploadLabel>
        <UploadInner ref={ref} style={{ backgroundImage: url ? `url(${url})` : null }}>
          <input
            id={id}
            name={name}
            type="file"
            style={{ display: 'none' }}
            onChange={event => {
              api
                .uploadLogo(companyId, event.target.files[0])
                .then(setUrl)
                .then(setUploadError(null))
                .catch((error) => {
                  if (error.response) {
                    setUploadError(error.response.data);
                  }
                });
            }}
          />
          {(!url || hover) && (
            <AddLogo hover={hover}>
              <FontAwesomeIcon icon="camera" size="2x" style={{ marginBottom: 8 }} />
              <div style={{ fontWeight: 500 }}>
                {url ? t('createCompany.changeLogo') : t('createCompany.addLogo')}
              </div>
            </AddLogo>
          )}
        </UploadInner>
        <StatusBar hasError={Boolean(error)} />
      </UploadContainer>
      {error && <ErrorMessage error={error} />}
      {uploadError && <ErrorMessage error={uploadError} />}
    </OuterContainer>
  );
};

export default UploadLogo;
