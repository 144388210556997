import ChooseCompanyType from './ChooseCompanyType';
import RegisterCompany from './RegisterCompany';
import DescribeCompany from './DescribeCompany';
import getRoutes from './getRoutes';

// Defines the sequence of steps for the wizard
const steps = [
  { id: 'type', Component: ChooseCompanyType },
  { id: 'register', Component: RegisterCompany },
  { id: 'describe', Component: DescribeCompany },
];

// Generate routes and add them to each object
for (let index = 0; index < steps.length; index += 1) {
  steps[index].routes = getRoutes(steps, index);
  steps[index].isLast = index === steps.length - 1;
}

export default steps;
