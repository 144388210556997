import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

const defaultEvents = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];
const IDLE_TIMOUT = 60 * 1000;

export const useIdle = (
  ms: number = IDLE_TIMOUT,
  initialState = false,
  events = defaultEvents,
): boolean => {
  const [state, setState] = useState<boolean>(initialState);

  useEffect(() => {
    let mounted = true;
    let timeout: any;
    let localState: boolean = state;
    const set = (newState: boolean) => {
      if (mounted) {
        localState = newState;
        setState(newState);
      }
    };

    const onEvent = throttle(() => {
      if (localState) {
        set(false);
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => set(true), ms);
    }, 50);

    const onVisibility = () => {
      if (!document.hidden) {
        onEvent();
      }
    };

    for (let i = 0; i < events.length; i += 1) {
      window.addEventListener(events[i], onEvent);
    }
    document.addEventListener('visibilitychange', onVisibility);

    timeout = setTimeout(() => set(true), ms);

    return () => {
      mounted = false;

      for (let i = 0; i < events.length; i += 1) {
        window.removeEventListener(events[i], onEvent);
      }
      document.removeEventListener('visibilitychange', onVisibility);
    };
    // TODO check if it's safe to add the missing dependency 'state'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ms, events]);

  return state;
};
