import styled from 'styled-components';
import Header from './Header';
import Title from './Title';

/* Flexbox-centric components */

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
`;

export const Cell = styled.div`
  position: relative;
  align-self: ${props => props.align};
`;

/* Page Layout components */

const Container = styled.div`
  height: 100%;
`;

export const Content = styled.div`
  width: 85%;
  max-width: ${props => props.wide ? '810px' : '540px'};
  margin: 0 auto;
  margin-top: 65px;
  padding-top: 55px;
  padding-bottom: 120px;
  height: fit-content;
`;

export const Page = ({ title, wide, children, hideHeader = false }) => (
  <Container>
    {title && (
      <Title text={title} />
    )}
    <Row justify="flex-end" style={{ height: '100%' }}>
      {hideHeader ? null : <Header />}
      <Content wide={wide}>
        {children}
      </Content>
    </Row>
  </Container>
);

export const CenteredPage = ({ title, children }) => (
  <Container>
    {title && (
      <Title text={title} />
    )}
    <Row justify="center" align="center" style={{ height: '100%' }}>
      {children}
    </Row>
  </Container>
);
