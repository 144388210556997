import { CompanyMinimized, Attachment } from '../rfq-utils/types';

export const enum QuestionElementType {
  RADIO = 'radio',
  TABLE = 'table',
  TEXTBOX = 'textbox',
  NUMBER = 'number',
  USER = 'add-user',
  DATE_TIME = 'datetime',
  MONEY = 'money',
  ADDRESS = 'address',
  LIST = 'list',
  DROPDOWN = 'dropdown',
  ATTACHMENTS = 'attachments',
  HIDDEN = 'hidden',
  SWITCH = 'switch',
  CHECKBOX = 'checkbox',
}

export enum SimpleAnswerValue {
  YES = 'yes',
  NO = 'no',
}

interface CustomValidation {
  name: string;
  message: string;
  fn: (any) => boolean;
}

export interface ValidationConfig {
  min?: number | Date | string;
  max?: number | Date | string;
  allow?: number | Date | string;
  required?: boolean;
  format?: 'integer.positive' | {
    regex: string;
    message: string;
  };
  maxLength?: number;
  customValidations?: CustomValidation[];
}

export interface QuestionElementOption {
  value: string;
  key: string;
  label: string;
  match?: { [key: string]: any };
  type?: 'date';
  description?: string;
}

export interface YearConfiguration {
  type: 'year';
}

export interface BaseQuestionElement {
  key: string;
  label?: string;
  labelId?: string;
  options?: QuestionElementOption[];
  validAnswerKeys?: string[];
  inputType?: 'text' | 'money' | 'number';
  isOptional?: boolean;
  match?: { [key: string]: any };
  validation?: ValidationConfig;
  helperText?: string;
  disabled?: boolean;
}

export interface AttachmentsQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.ATTACHMENTS;
  limit?: number;
}

export interface TextboxQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.TEXTBOX;
  isMultiline?: boolean;
  rows?: number;
}

export interface NumberQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.NUMBER;
}

export interface RadioQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.RADIO;
  options: QuestionElementOption[];
}

export interface UserQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.USER;
}

export type ColumnOrRowOptions =
  QuestionElementOption[] |
  YearConfiguration;

export interface TableQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.TABLE;
  columnOptions: ColumnOrRowOptions;
  rowOptions: ColumnOrRowOptions;
}

export interface DateTimeQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.DATE_TIME;
  hasTime: boolean;
  fullWidth?: boolean;
  dateFormat?: string;
}

export interface MoneyQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.MONEY;
}

export interface AddressQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.ADDRESS;
}

export interface ListQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.LIST;
  itemNameId: string;
  elements: QuestionElement[];
  template: string;
  addButtonLabelId?: string;
}
export interface DropdownQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.DROPDOWN;
  subtype?: 'country' | 'currency' | 'locationType';
  isMultiSelect?: boolean;
}

export interface SwitchQuestionElement extends BaseQuestionElement {
  type: QuestionElementType.SWITCH;
  switchText: string;
}

export type QuestionElement =
  TextboxQuestionElement |
  NumberQuestionElement |
  RadioQuestionElement |
  DropdownQuestionElement |
  DateTimeQuestionElement |
  UserQuestionElement |
  MoneyQuestionElement |
  AddressQuestionElement |
  ListQuestionElement |
  TableQuestionElement |
  AttachmentsQuestionElement |
  SwitchQuestionElement;

export interface Question {
  key: string;
  sentence: string;
  elements: QuestionElement[];
  mandatoryUpload?: boolean;
  optionalUpload?: boolean;
  deprecated?: boolean;
  hasUploadError?: boolean;
}

export interface Category {
  key: string;
  name: string;
  label?: string;
  questions: Question[];
}

export interface Questionnaire {
  _id: string;
  name: string;
  companyId: string;
  categories: Category[];
}

export interface CategorySummary {
  numQuestions: number;
  numCompleted: number;
}

export interface CategorySummaries {
  [key: string]: CategorySummary;
}

export interface IssueDates {
  [key: string]: string;
}

export interface QuestionSet {
  [key: string]: boolean;
}

export type SimpleAnswer = {
  value: SimpleAnswerValue;
  attachments?: Attachment[];
};

export type ComplexAnswer = {
  [key: string]: any;
  value?: string;
  attachments?: Attachment[];
};

export type Answer =
  SimpleAnswer |
  ComplexAnswer;

export interface Answers {
  [key: string]: Answer;
}

export type AccessRequest = {
  companyId: string;
  status: 'pending' | 'approved' | 'denied';
  company: CompanyMinimized;
  userId: string | null;
  timestamp: number | null;
};

export interface AnswerSet {
  accessRequests: Record<string, AccessRequest>;
  company: CompanyMinimized;
  answers: Answers;
  categorySummaries?: CategorySummaries;
}
