import { isEmpty, isEqual } from 'lodash';
import { useState, useEffect } from 'react';

/* eslint-disable consistent-return */

// Re-runs the effect if the value does not deep-equal previous run
const useDeepEqualEffect = (effect, value) => {
  const [previousValue, setPreviousValue] = useState(value);

  useEffect(
    () => {
      if (!isEmpty(value) && !isEqual(value, previousValue)) {
        const handleUnmount = effect();
        setPreviousValue(value);
        return handleUnmount;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );
};

export default useDeepEqualEffect;
