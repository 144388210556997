import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from './Button';

export function LoginButton({ ...buttonProps }) {
  const history = useHistory();
  const { t } = useTranslation('onboarding');

  return (
    <Button
      {...buttonProps}
      type="button"
      onClick={() => history.push(('/login'))}
    >
      {t('general.logIn')}
    </Button>
  );
}
