import * as React from 'react';
import { find, map, toNumber, keys, sortBy, isEmpty } from 'lodash';
import { Flex, Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Color } from '@deepstream/ui-kit/theme/types';

const Bar = styled.div<any>`
  border-radius: ${props => props.height};
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props => props.theme.colors.lightGray2};
  border-radius: ${props => props.height};
  position: relative;
  overflow-x: hidden;
`;

const Progress = styled(Box)`
  position: absolute;
  left: 0;
  height: ${props => props.height};
  width: ${props => props.width};
  transition: width 100ms;
`;

type ProgressBarProps = {
  variant?: 'warn' | 'primary' | 'success';
  percentage: number;
  height: number | string;
  width?: number | string;
};

export const ProgressBarObsolete: React.FC<ProgressBarProps> = ({ percentage, variant = 'success', width = '100%', height = '20px' }) => (
  <Bar width={width} height={height}>
    <Progress height={height} width={`${percentage}%`} bg={variant} />
  </Bar>
);

type Variant = 'valueBased' | 'info' | 'warn' | 'error' | 'success';

type Intervals = Record<number, Variant>;

const VariantColorMap: Partial<{ [k in Variant]: Color }> = {
  'info': 'primary',
  'warn': 'warning',
  'error': 'danger',
  'success': 'success',
};

const normalize = (value: number, min: number, max: number) => ((value - min) * 100) / (max - min);

export const DEFAULT_INTERVALS: Intervals = {
  49: 'error',
  74: 'warn',
  100: 'success',
};

export const getValueFromIntervals = (value: number, intervals: Intervals = DEFAULT_INTERVALS): [Variant, number] => {
  const intervalValues = map(
    keys(intervals),
    (key) => toNumber(key),
  );

  const interval = find(
    sortBy(intervalValues),
    (k) => k >= value,
  );

  return [
    // @ts-expect-error ts(2538) FIXME: Type 'undefined' cannot be used as an index type.
    intervals[interval],
    // @ts-expect-error ts(2322) FIXME: Type 'number | undefined' is not assignable to type 'number'.
    interval,
  ];
};

export const ProgressBar = ({
  value: valueProp,
  showValue = true,
  normalizeMin = 0,
  normalizeMax = 100,
  ...props
}: {
  value: number;
  showValue?: boolean;

  normalizeMin?: number;
  normalizeMax?: number;
} & ({ variant: 'info' | 'warn' | 'error' | 'success'; }
    | { variant: 'valueBased'; intervals?: Intervals }),
) => {
  const value = normalize(valueProp, normalizeMin, normalizeMax);

  // @ts-expect-error ts(2322) FIXME: Type 'Color | undefined' is not assignable to type 'Color'.
  let colorVariant: Color = VariantColorMap[props.variant];

  if (props.variant === 'valueBased') {
    const [intervalVariant] = getValueFromIntervals(
      value,
      isEmpty(props.intervals) ? DEFAULT_INTERVALS : props.intervals,
    );

    // @ts-expect-error ts(2322) FIXME: Type 'Color | undefined' is not assignable to type 'Color'.
    colorVariant = VariantColorMap[intervalVariant];
  }

  return (
    <Flex alignItems="center">
      <Box
        role="progressbar"
        aria-valuenow={valueProp}
        aria-valuemin={normalizeMin}
        aria-valuemax={normalizeMax}
        sx={{
          width: '100%',
          position: 'relative',
          backgroundColor: 'lightGray2',
          height: 8,
          borderRadius: 'small',
          overflow: 'hidden',
          ':before': {
            content: "''",
            position: 'absolute',
            borderRadius: 'small',
            transition: 'width 0.3s ease-in',
            left: 0,
            top: 0,
            bottom: 0,
            width: `${value}%`,
            backgroundColor: colorVariant || VariantColorMap.info,
          },
        }}
      />
      {showValue && (
        <Text ml={3} fontWeight={500} fontSize={2} lineHeight={1} sx={{ overflowWrap: 'normal' }}>
          {value}%
        </Text>
      )}
    </Flex>
  );
};
